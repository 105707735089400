export const bg = {
    bar: {
        wallet: "মানিব্যাগ",
        activity: "কর্মক্ষেত্র",
        profile: "প্রোফাইল"
    },
    wallet: {
        totalBalance: "$Daily মোট ব্যালেন্স",
        send: "প্রেরণ",
        swap: "মুদ্রা বিনিময়",
        deposit: "আদান",
        more: "আরও সম্পদ",
        claimReward: "আপনার দৈনিক পুরস্কার গ্রহণ করুন",
        DrawReward: "$Daily ভাগ্যপ্রাপ্ত চক্র",
        wayTitle: "$Daily এর অধিক পেতে উপায়ের শিরোনাম",
        inviteTitle: "আপনার বন্ধুদেরকে আমন্ত্রণ জানান",
        followTitle: "X অ্যাকাউন্ট অনুসরণ করুন এবং চ্যানেলে যোগদান করুন"
    },
    activity: {
        tap: "লটারি শুরু করুন",
        comingsoon: "আসুন আরও অপেক্ষা করুন",
        use100: "লটারি খেলতে 100 $Daily ব্যবহার করুন",
        dice: {
            rule1: "1. প্রতিদিনের ভাগ্যপ্রাপ্ত লটারি সুযোগ: প্রতিদিন চেকইন শেষে একটি প্রাথমিক সুযোগ পান। প্রতি লটারি খেলার জন্য 100 $Daily খরচ হবে",
            rule2: "2. প্রতারণার অপরিহার্য: বট বা প্রতারক ব্যবহার করা এবং প্রতারণা সহিত সাধারণ ব্যবহারকারী যারা আইডি করবেন তাদের ক্ষমতা বাতিল করা হবে"
        }
    },
    profile: {
        copylink: "লিঙ্ক অনুলিপি",
        share: "শেয়ার",
        earnReward: "পুরস্কার অর্জন",
        recommend: "পরামর্শ করা",
        invite: "বন্ধুদের আমন্ত্রণ করুন",
        link: "লিঙ্ক",
        statistics: "আমন্ত্রণ তথ্য",
        firstReferral: "প্রথম আমন্ত্রণ",
        secondReferral: "দ্বিতীয় আমন্ত্রণ",
        checkInReward: "প্রতিদিনের সাইন ইন পুরস্কারঃ",
        referralReward: "আমন্ত্রণ পুরস্কারঃ",
        dearUser: "প্রিয় @{username}"
    },
    setting: {
        version: "Daily Wallet সংস্করণ",
        language: "ভাষা",
        support: "সহায়তা পান",
        chooseLanguage: "আপনার ভাষা চয়ন করুন",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "সম্প্রদায়",
        announcement: "ডেইলি ওয়ালেট সংবাদ ঘোষণা",
        channel: "টেলিগ্রাম চ্যানেল",
        twitter: "অফিসিয়াল X অ্যাকাউন্ট",
        whitepaper: "whitepaper",
        support: "সাহায্য পেতে"
    },
    common: {
        pleaseOpenInTelegram: "দয়া করে টেলিগ্রামে খোলুন",
        pleaseOpenPerDay: "দয়া করে প্রতিদিন শুধুমাত্র একবার লটারি খুলুন",
        pleaseOpenBalance: "আপনার ব্যালেন্স 100 $Daily এর নিচে",
        PleaseOpenfirst: "দয়া করে প্রথমে প্রতিদিন লাভ নেওয়া জাচ্ছে",
        Congratulations: "অভিনন্দন! আপনি জিতেছেন",
        tomorrow: "$Daily! আগামীকাল আবার আপনার ভাগ্য পরীক্ষা করুন!",
        hours: "24 ঘন্টার মধ্যে আপনি প্রতিদিন লাভ নেওয়া জাচ্ছেন, অনুগ্রহ করে পরে আবার চেষ্টা করুন",
        successfully: "আপনি সফলভাবে প্রতিদিন লাভ নেওয়া জাচ্ছেন!",
        Twitter: "আপনার X অ্যাকাউন্ট যাচাই করা হয়েছে, 800 $Daily আপনার ওয়ালেটে প্রেরিত হয়েছে",
        verify: "যাচাই করুন",
        TGChatChannel: "আমাদের TG চ্যাট চ্যানেলে যোগদান করলে 500 $Daily পান",
        joinedOurChat: "আপনি আমাদের চ্যাট চ্যানেলে যোগদান করেছেন, 500 $Daily আপনার ওয়ালেটে প্রেরিত হয়েছে",
        VerificationSuccessful: "যাচাই সফল",
        ToComplete: "যাচাই সম্পন্ন করতে, আমাদের চ্যাট গ্রুপে যোগদান করুন",
        TheVerification: "এই যাচাইটি 24 ঘন্টার মধ্যে সম্পন্ন হবে",
        ProgramReferral: "পরামর্শ প্রোগ্রামের নির্দেশিকা",
        HowMany: "আমি কতোটি $Daily পেতে পারি?",
        Daily: "$Daily!",
        WhentheUsers: "যখন আপনার আমন্ত্রিতকারীরা পুরস্কার নেয়, তখন আপনি",
        DailyActivity: "প্রতিদিনের কার্যক্রম পুরস্কার: আপনি যখন আপনার আমন্ত্রিত বন্ধুরা তাদের প্রতিদিনের পুরস্কার গ্রহণ করেন, তখন আপনি পান",
        DailyFor: "প্রতি প্রমোশন $Daily!",
        SecondLevel: "দ্বিতীয় স্তরের পুরস্কার: যখন আমন্ত্রিত ব্যক্তিরা অন্যান্য ব্যক্তিরা ডেইলি ওয়ালেট নিবন্ধন করে, তখন আপনি পান",
        FirstLevel: "প্রথম স্তরের পুরস্কার: যখন কেউ আপনার পরামর্শ লিঙ্ক ব্যবহার করে ডেইলি ওয়ালেট অ্যাকাউন্ট সক্রিয় করে, তখন আপনি পান",
        CopySuccessful: "অনুলিপি সফল",
        RefreshFailed: "রিফ্রেশ ব্যর্থ"
    },
    errors: {
        tryAgain: "ভুল! অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন",
        twitterSubmitted: "আপনার X অ্যাকাউন্টের নাম সংরক্ষণ করা হয়েছে",
        inputTwitter: "অনুগ্রহ করে সামনে '@' সহ সঠিক X অ্যাকাউন্টের নাম লিখুন"
    }

}