import Vue from "vue";
import VueRouter from "vue-router";
import Activity from "../views/Activity/index.vue";
import turntable from "../views/Activity/turntable/turntable";
import Profile from "../views/Profile/index.vue";
import ProfileIndex from "../views/Profile/Profile.vue";
import Wallet from "../views/Wallet/index.vue";
import setup from "@/views/Wallet/settings/setup.vue";
import multilingual from "@/views/Wallet/settings/multilingual.vue";
import settings from "@/views/Wallet/settings/index.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "Wallet",
            component: Wallet,
        },
        {
            path: "/Activity",
            name: "Activity",
            component: Activity,
        },
        {
            path: "/Profile",
            name: "Profile",
            component: Profile,
        },
        {
            path: "/ProfileIndex",
            name: "ProfileIndex",
            component: ProfileIndex,
        },
        {
            path: "/turntable",
            name: "turntable",
            component: turntable,
        },
        {
            path: "/setup",
            name: "setup",
            component: setup,
        },
        {
            path: "/multilingual",
            name: "multilingual",
            component: multilingual,
        },
        {
            path: "/settings",
            name: "settings",
            component: settings,
        },
    ],
});

export default router;
