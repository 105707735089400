export const rs = {
    bar: {
        wallet: "кошелек",
        activity: "деятельность",
        profile: "профиль"
    },
    wallet: {
        totalBalance: "$Daily общий баланс",
        send: "отправить",
        swap: "обмен монет",
        deposit: "пополнение",
        more: "больше активов",
        claimReward: "получите вашу ежедневную награду",
        DrawReward: "$Daily счастливое колесо фортуны",
        wayTitle: "Способы получения больше $Daily",
        inviteTitle: "пригласите ваших друзей присоединиться",
        followTitle: "подпишитесь на аккаунт X и присоединитесь к чат-каналу"
    },
    activity: {
        tap: "начать розыгрыш",
        comingsoon: "ожидайте больше акций",
        use100: "используйте 100 $Daily для розыгрыша",
        dice: {
            rule1: "1. Ежедневный шанс на удачу в лотерее: получите один начальный шанс после выполнения ежедневной регистрации. Каждая попытка розыгрыша будет стоить 100 $Daily",
            rule2: "2. Запрет на обман: строго запрещено использование ботов или обман, нарушители будут дисквалифицированы",
        }
    },
    profile: {
        copylink: "копировать ссылку",
        share: "поделиться",
        earnReward: "заработать награду",
        recommend: "рекомендовать",
        invite: "пригласить друзей",
        link: "ссылка",
        statistics: "статистика приглашений",
        firstReferral: "первый уровень приглашений",
        secondReferral: "второй уровень приглашений",
        checkInReward: "ежедневная награда за регистрацию:",
        referralReward: "награда за рекомендацию:",
        dearUser: "дорогой @{username}",
    },
    setting: {
        version: "версия Daily Wallet",
        language: "язык",
        support: "получить помощь",
        chooseLanguage: "выберите ваш язык",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Runssian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "сообщество",
        announcement: "объявления Daily Wallet",
        channel: "Telegram канал чата",
        twitter: "официальный аккаунт X",
        whitepaper: "Whitepaper",
        support: "получить помощь",
    },
    common: {
        pleaseOpenInTelegram: "пожалуйста, откройте в Telegram",
        pleaseOpenPerDay: "вы можете участвовать в лотерее только один раз в день",
        pleaseOpenBalance: "на вашем счету недостаточно средств, менее 100 $Daily",
        PleaseOpenfirst: "пожалуйста, сначала получите ежедневный бонус за регистрацию",
        Congratulations: "поздравляем! Вы выиграли",
        tomorrow: "$Daily! Попробуйте свою удачу завтра!",
        hours: "вы уже получили ежедневный бонус за регистрацию в течение 24 часов, попробуйте позже",
        successfully: "вы успешно получили ежедневный бонус за регистрацию!",
        Twitter: "ваш аккаунт X подтвержден, 800 $Daily отправлены на ваш кошелек",
        verify: "подтвердить",
        TGChatChannel: "присоединяйтесь к нашему TG чат-каналу и получите 500 $Daily",
        joinedOurChat: "вы присоединились к нашему чат-каналу, 500 $Daily отправлены на ваш кошелек",
        VerificationSuccessful: "проверка успешна",
        ToComplete: "чтобы завершить проверку, пожалуйста, присоединитесь к нашей чат-группе",
        TheVerification: "эта проверка будет завершена в течение 24 часов",
        ProgramReferral: "правила реферальной программы",
        HowMany: "сколько $Daily я могу получить через реферальную программу?",
        Daily: "$Daily!",
        WhentheUsers: "когда ваши приглашенные получают награды, вы также получите",
        DailyActivity: "ежедневная активная награда: когда приглашенный друг получает его ежедневную награду, вы получите",
        DailyFor: "за каждое приглашение $Daily!",
        SecondLevel: "награда второго уровня: когда приглашенные регистрируют других в Daily Wallet, вы получите награду",
        FirstLevel: "награда первого уровня: когда кто-то активирует учетную запись Daily Wallet с вашей реферальной ссылкой, вы получите награду",
        CopySuccessful: "копирование успешно",
        RefreshFailed: "обновление не удалось",
        errors: {
            tryAgain: "ошибка! пожалуйста, попробуйте позже",
            twitterSubmitted: "имя вашего аккаунта X уже сохранено",
            inputTwitter: "пожалуйста, введите правильное имя аккаунта X, начиная с '@'"
        }
    }
}