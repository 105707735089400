export const vnm = {
    bar: {
        wallet: 'Ví',
        activity: 'Hoạt động',
        profile: "Hồ sơ cá nhân",
    },
    wallet: {
        totalBalance: "Tổng số dư của $Daily",
        send: "Gửi đi",
        swap: "Hoán đổi",
        deposit: "Gửi tiền",
        more: "Thêm tài sản",
        claimReward: "Nhận phần thưởng hàng ngày của bạn",
        DrawReward: "Rút thêm $Daily",
        wayTitle: "Cách để nhận thêm $Daily",
        inviteTitle: "Mời bạn bè tham gia",
        followTitle: "Theo dõi tài khoản X và tham gia kênh trò chuyện",
    },
    activity: {
        tap: "Nhấn vào đây",
        comingsoon: "Sắp có thêm",
        use100: "Sử dụng 100 $Daily để rút thăm",
        dice: {
            rule1: "1. Cơ hội quay số may mắn hàng ngày: Kiếm được một cơ hội thô mỗi ngày sau khi hoàn thành việc đăng nhập hàng ngày. Mỗi lần quay sẽ tiêu tốn 100 $Daily.",
            rule2: "2. Không gian lận: Việc sử dụng bot hoặc gian lận nghiêm cấm và dẫn đến loại trừ",
        }
    },
    profile: {
        copylink: "Sao chép liên kết",
        share: "Chia sẻ",
        earnReward: "Kiếm phần thưởng",
        recommend: "Giới thiệu",
        invite: "Mời bạn bè",
        link: "Liên kết",
        statistics: "Thống kê",
        firstReferral: "Người được mời cấp một",
        secondReferral: "Người được mời cấp hai",
        checkInReward: "Phần thưởng đăng nhập hàng ngày:",
        referralReward: "Phần thưởng giới thiệu:",
        dearUser: "Kính gửi @{username}",
    },
    setting: {
        version: "Phiên bản Daily Wallet",
        language: "Ngôn ngữ",
        support: "Liên hệ hỗ trợ",
        chooseLanguage: "Chọn ngôn ngữ của bạn",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Cộng đồng",
        announcement: "Thông báo của Daily Wallet",
        channel: "Kênh trò chuyện Telegram",
        twitter: "Tài khoản chính thức X",
        whitepaper: "Whitepaper",
        support: "Được giúp đỡ",
    },
    common: {
        pleaseOpenInTelegram: "Vui lòng mở trong Telegram",
        pleaseOpenPerDay: "Bạn chỉ có thể quay một lần mỗi ngày",
        pleaseOpenBalance: "Số dư không đủ",
        PleaseOpenfirst: "Vui lòng nhận phần thưởng đăng nhập hàng ngày của bạn trước",
        Congratulations: "Chúc mừng! Bạn đã chiến thắng",
        tomorrow: "$Daily! Hãy thử vận may của bạn lại vào ngày mai!",
        hours: "Bạn đã nhận phần thưởng đăng nhập hàng ngày trong vòng 24 giờ, vui lòng thử lại sau",
        successfully: "Bạn đã nhận thành công phần thưởng đăng nhập hàng ngày!",
        Twitter: "Tài khoản X của bạn đã được xác minh, 800 $Daily đã được gửi vào ví của bạn",
        verify: "xác minh",
        TGChatChannel: "Tham gia kênh trò chuyện TG của chúng tôi để nhận 500 $Daily",
        joinedOurChat: "Bạn đã tham gia kênh trò chuyện của chúng tôi, 500 $Daily đã được gửi vào ví của bạn",
        VerificationSuccessful: "Xác minh thành công",
        ToComplete: "Để hoàn thành xác minh, vui lòng tham gia nhóm trò chuyện của chúng tôi trước",
        TheVerification: "Quá trình xác minh sẽ mất 24 giờ để hoàn thành",
        ProgramReferral: "Quy tắc Chương trình Giới thiệu",
        HowMany: "Bạn có thể nhận được bao nhiêu $Daily thông qua chương trình giới thiệu?",
        Daily: "$Daily!",
        WhentheUsers: "Khi người dùng mà bạn mời nhận phần thưởng của họ, bạn sẽ nhận được",
        DailyActivity: "Phần thưởng Hoạt động Hàng ngày: Khi bạn bè được mời của bạn nhận phần thưởng hàng ngày, bạn sẽ nhận được",
        DailyFor: "$Daily cho mỗi lượt giới thiệu!",
        SecondLevel: "Phần thưởng cấp độ thứ hai: Khi người được mời giới thiệu người khác đăng ký Daily Wallet, bạn sẽ nhận được phần thưởng",
        FirstLevel: "Phần thưởng Cấp độ Đầu tiên: Khi ai đó kích hoạt tài khoản Daily Wallet bằng cách sử dụng liên kết giới thiệu của bạn, bạn sẽ nhận được phần thưởng",
        CopySuccessful: "Sao chép thành công",
        RefreshFailed: "Làm mới thất bại",
    },
    errors: {
        tryAgain: "sai lầm! Vui lòng thử lại sau",
        twitterSubmitted: "Tên tài khoản X của bạn đã được lưu",
        inputTwitter: "Vui lòng nhập đúng tên tài khoản X, có ký tự '@' phía trước"
    }
}
