export const jp = {
    bar: {
        wallet: 'ウォレット',
        activity: 'アクティビティ',
        profile: "プロフィール",
    },
    wallet: {
        totalBalance: "$Daily 総残高",
        send: "移行",
        swap: "スワップ",
        deposit: "入金",
        more: "その他の資産",
        claimReward: "デイリーリワードを請求",
        DrawReward: "$Daily ラッキーホイール",
        wayTitle: "より多くの $Daily を獲得する方法",
        inviteTitle: "友達を招待する",
        followTitle: "X アカウントをフォローしてチャットチャンネルに参加する",
    },
    activity: {
        tap: "ここをタップ",
        comingsoon: "もっと近日公開",
        use100: "抽選には 100 $Daily を使用します",
        dice: {
            rule1: "1. デイリーラッキードローのチャンス：毎日のチェックインを完了すると、1回の生のチャンスを獲得します。各ドローは100 $Dailyを消費します。",
            rule2: "2. 不正行為の禁止：ボットの使用や不正行為は厳しく禁止されており、失格につながります",
        }
    },
    profile: {
        copylink: "リンクをコピー",
        share: "共有",
        earnReward: "報酬を獲得",
        recommend: "おすすめ",
        invite: "友達を招待",
        link: "リンク",
        statistics: "統計",
        firstReferral: "第一レベルの招待者",
        secondReferral: "第 2 レベルの招待者",
        checkInReward: "デイリーチェックイン報酬：",
        referralReward: "紹介報酬：",
        dearUser: "@{username} 様",
    },
    setting: {
        version: "Daily Wallet バージョン",
        language: "言語",
        support: "サポートに連絡",
        chooseLanguage: "言語を選択",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "コミュニティ",
        announcement: "Daily Wallet アナウンスメント",
        channel: "Telegram チャットチャンネル",
        twitter: "公式 X アカウント",
        whitepaper: "Whitepaper",
        support: "助けを得ます",
    },
    common: {
        pleaseOpenInTelegram: "Telegramで開いてください",
        pleaseOpenPerDay: "1日に1回だけ回すことができます",
        pleaseOpenBalance: "残高不足です",
        PleaseOpenfirst: "まずは毎日のサインイン特典を受け取ってください",
        Congratulations: "おめでとうございます！勝ちました",
        tomorrow: "$Daily! 明日もう一度運を試してみてください！",
        hours: "24時間以内にデイリーチェックイン報酬を請求しました。後ほど再試行してください",
        successfully: "デイリーチェックイン報酬を正常に請求しました！",
        Twitter: "Xアカウントが認証され、800 $Dailyが財布に送られました",
        verify: "認証",
        TGChatChannel: "TGチャットチャンネルに参加して500 $Dailyを獲得",
        joinedOurChat: "チャットチャンネルに参加しました。500 $Dailyが財布に送られました",
        VerificationSuccessful: "認証に成功しました",
        ToComplete: "認証を完了するには、まずチャットグループに参加してください",
        TheVerification: "認証が完了するまでに24時間かかります",
        ProgramReferral: "紹介プログラムのルール",
        HowMany: "紹介プログラムでいくつの $Daily を獲得できますか？",
        Daily: "$Daily！",
        WhentheUsers: "あなたの招待によって参加したユーザーが報酬を請求すると、あなたは獲得します",
        DailyActivity: "デイリーアクティビティ報酬：招待した友達が毎日の報酬を請求すると、あなたは受け取ります",
        DailyFor: "紹介ごとに $Daily！",
        SecondLevel: "第二レベル報酬：招待された人が他の人にDaily Walletへの登録を紹介すると、報酬が得られます",
        FirstLevel: "第一レベル報酬：誰かがあなたの紹介リンクを使用してDaily Walletアカウントを活性化すると、報酬を受け取ります",
        CopySuccessful: "コピー成功",
        RefreshFailed: "更新に失敗しました",
    },
    errors: {
        tryAgain: "間違い！後でもう一度試してください",
        twitterSubmitted: "X アカウント名が保存されました",
        inputTwitter: "先頭に「@」を付けて、正しい X アカウント名を入力してください."
    }

}