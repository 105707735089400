<template>
  <div class="index">
    <div class="list_top">Choose Your Language</div>
    <div class="list_item">
      <RadioGroup v-model="this.lang" class="radio">
        <Radio
            @click="changeLang(item.type)"
            :name="item.name"
            checked-color="#7B19EC"
            v-for="(item, index) in radiolist1"
            :key="index"
        >
          {{ item.title }}
        </Radio>
      </RadioGroup>
    </div>
  </div>
</template>

<script>
import {RadioGroup, Radio} from "vant";

export default {
  components: {
    RadioGroup,
    Radio,
  },


  data() {
    return {
      radio: "English",
      radiolist1: [
        {
          type: "en",
          name: "en",
          title: "English",
          disabled: this.lang === "en",
        },
        {
          type: "zh",
          name: "zh",
          title: "中文",
          disabled: this.lang === "zh",
        },
        {
          type: "ind",
          name: "ind",
          title: "bahasa Indonesia",
          disabled: this.lang === "ind",
        },
        {
          type: "kr",
          name: "kr",
          title: "한국인",
          disabled: this.lang === "kr",
        },
        {
          type: "es",
          name: "es",
          title: "Español",
          disabled: this.lang === "es",
        },
        {
          type: "jp",
          name: "jp",
          title: "日本語",
          disabled: this.lang === "jp",
        },
        {
          type: "pt",
          name: "pt",
          title: "Português",
          disabled: this.lang === "pt",
        },
        {
          type: "vnm",
          name: "vnm",
          title: "Türkçe",
          disabled: this.lang === "vnm",
        },
        {
          type: "tr",
          name: "tr",
          title: "Tiéng Việt",
          disabled: this.lang === "tr",
        },
        {
          type: "ar",
          name: "ar",
          title: "يبرع ",
          disabled: this.lang === "ar",
        },
        {
          type: "india",
          name: "india",
          title: "हिन्दी",
          disabled: this.lang === "india",
        },
        {
          type: "fr",
          name: "fr",
          title: "Français",
          disabled: this.lang === "fr",
        },
        {
          type: "rs",
          name: "rs",
          title: "Русский язык",
          disabled: this.lang === "rs",
        },
        {
          type: "bg",
          name: "bg",
          title: "বাংলা",
          disabled: this.lang === "bg",
        },
      ],
    };
  },

  computed: {
    lang() {
      return this.$store.state.user.language;
    }
  },

  methods: {
    changeLang(lang) {

      if (name === this.lang) {
        this.$router.back();
      }

      this.$store.dispatch("changeLang", lang).then(() => {
        this.$router.back();
      });

    },
  },
  mounted() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        this.$router.back();
      });
    }
  },
  destroyed() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide();
    }
  }
};
</script>

<style lang="less" scoped>
.index {
  background: url("../../../assets/image/new.png");
  background-size: 100% 100%;
  //height: 100vh;

  .radio {
    .van-radio {
      margin-bottom: 32px;
    }

    ::v-deep .van-icon {
      background: #e9e9e9;
      width: 17px;
      height: 17px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .van-icon-success:before {
      font-size: 10px;
    }
  }

  .list_top {
    padding-top: 40px;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin: 0 0 11px 15px;
    padding-top: 55px;
  }

  .list_item {
    box-sizing: border-box;
    width: 355px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    opacity: 1;
    padding: 33px 0 0 23px;
  }
}

/deep/ .van-radio__label {
  font-size: 16px;
}
</style>
