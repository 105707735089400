import { createStore } from 'vuex'
import request from '@/request'
import Vuex from 'vuex'
import Vue from 'vue'
import { i18n } from "@/langs";

Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
        user: null,
        authToken: null,
        balance: null,
        loading: true
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setBalance(state, balance) {
            state.balance = balance
        },
        setToken(state, token) {
            state.authToken = token
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setLastCheckin(state, lastCheckin) {
            state.user.lastCheckIn = lastCheckin
        },
        setLang(state, lang) {
            if (state.user && state.user.language) {
                i18n.locale = lang
                const user = state.user
                user.language = lang
                state.user = user
            }
        }
    },
    actions: {
        async auth({ commit }, msg) {
            const data = await request.post('/auth/login', {
                msg,
                type: "telegramMiniApp"
            })
            const token = data.data.data.access_token
            commit('setToken', token)

            const userData = await request.get('/users/profile', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            const user = userData.data.data

            commit('setUser', user)

            commit('setLang', user.language)
            const balanceData = await request.get('/wallet/balance', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            const balance = balanceData.data.data


            commit('setBalance', balance)
            commit("setLoading", false)
        },
        async changeLang({ commit, state }, lang) {
            try {
                await request.post('/users/changeLanguage', {
                    language: lang
                }, {
                    headers: {
                        authorization: `Bearer ${state.authToken}`
                    }
                })
                commit('setLang', lang)
                return true
            } catch (e) {
                return false;
            }
        }
    },
})