<template>
  <div>
    <div class="index">
      <div class="index_content">
        <img src="../../assets/image/10.png" mode="scaleToFill" />
      </div>
      <div class="index_title">{{ $t('common.ProgramReferral') }}</div>

      <div class="index_top">
        <div class="left">{{ $t('profile.recommend') }}</div>
        <div class="right">{{ $t('profile.invite') }}</div>
      </div>

      <div class="index_input">
        <van-field v-model="value" center placeholder="Link" class="text__value">
          <template #button>
            <span slot="suffix" margin="0 5px 0 0" type="tips" style="font-weight: 600">
              {{ referralLink }}
            </span>
          </template>
        </van-field>

        <div class="btn" @click="copyToClipboard">
          {{ $t('profile.copylink') }}
        </div>

      </div>

      <div class="index_box">
        <div class="item">
          <img src="../../assets/image/11.png" mode="scaleToFill" />

          <div class="name">
            {{ $t('common.FirstLevel') }}
            <span>300</span> {{ $t('common.Daily') }}
          </div>
        </div>
        <div class="item" style="margin-top: 28px">
          <img src="../../assets/image/12.png" mode="scaleToFill" />
          <div class="name">
            {{ $t('common.SecondLevel') }}
            <span>200</span> {{ $t('common.DailyFor') }}
          </div>
        </div>
        <div class="item" style="margin-top: 28px">
          <img src="../../assets/image/13.png" mode="scaleToFill" />

          <div class="name">
            {{ $t('common.DailyActivity') }} <span>20</span>
            {{ $t('common.Daily') }}. {{ $t('common.WhentheUsers') }}
            <span>10</span>
            {{ $t('common.Daily') }}
          </div>
        </div>
        <van-overlay :show="showSuccess" @click="showSuccess = false">
          <div class="wrapper">
            <div class="disBox">
              <img src="../../assets/image/success.png" mode="scaleToFill" />
              <div class="text">{{ $t('common.CopySuccessful') }}</div>
            </div>
          </div>
        </van-overlay>
        <van-overlay :show="showError" @click="showError = false">
          <div class="wrapper">
            <div class="disBox">
              <img src="../../assets/image/error.png" mode="scaleToFill" />
              <div class="text">{{ $t('common.RefreshFailed') }}</div>
            </div>
          </div>
        </van-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import tabBar from '@/components/tabbar.vue'

export default {
  components: { tabBar },
  data() {
    return {
      value: '',
      showSuccess: false,
      showError: false,
    }
  },
  computed: {
    referralLink() {
      return `https://t.me/DailyWalletbot?start=${this.$store.state.user.userID}`
    },
  },
  mounted() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show()
      window.Telegram.WebApp.BackButton.onClick(() => {
        this.$router.back()
      })
    }
  },
  methods: {
    copyToClipboard() {
      this.$copyText(this.referralLink)
      this.showSuccess = true
    },
  },
  destroyed() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide()
    }
  },
}
</script>

<style scoped lang="less">
.text__value {
  width: 100%;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  border: none;
  background: #f8fafc;
}

.disBox {
  width: 129px;
  height: 32px;
  background: rgba(0, 0, 0, 0.73);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 14px;
    height: 14px;
  }

  .text {
    font-size: 12px;
    color: #ffffff;
  }
}

.index {
  background: url('../../assets/image/new.png');
  background-size: 100% 100%;
  height: 100vh;

  .index_content {
    height: 313px;
    margin: 0 auto;
    padding-top: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .index_title {
    margin-top: 10px;
    padding-left: 15px;
    font-size: 28px;
    font-family: TencentSans, TencentSans;
    font-weight: 600;
    color: #8d65ff;
    line-height: 33px;
    font-style: italic;
  }

  .index_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;
    margin-top: 6px;

    .left {
      font-size: 17px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #000000;
      line-height: 19px;
    }

    .right {
      font-size: 13px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #6e6e73;
      line-height: 25px;
    }
  }

  .index_input {
    width: 345px;
    height: 110px;
    background: #f8fafc;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    margin: 10px auto 0;
    padding: 15px;
    box-sizing: border-box;

    .btn {
      width: 100%;
      height: 44px;
      background: #000000;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      text-align: center;
      line-height: 44px;
      color: #fff;
      margin-top: 20px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .index_box {
    width: 345px;
    height: 236px;
    background: #f8fafc;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    margin: 15px auto 0;
    padding: 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
        margin-left: 5px;
      }

      .name {
        width: 275px;
        height: 42px;
        font-size: 12px;
        font-weight: 400;
        color: #6e6e73;
        line-height: 14px;

        span {
          color: #ff0002;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.van-cell {
  padding: 0;
}
</style>
