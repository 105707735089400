export const fr = {
    bar: {
        wallet: "Portefeuille",
        activity: "Activité",
        profile: "Profil",
    },
    wallet: {
        totalBalance: "Solde total $Daily",
        send: "Envoyer",
        swap: "Échange de crypto-monnaies",
        deposit: "Dépôt",
        more: "Plus d'actifs",
        claimReward: "Réclamez votre récompense quotidienne",
        DrawReward: "Roue de la fortune $Daily",
        wayTitle: "Comment obtenir plus de $Daily",
        inviteTitle: "Invite tes amis",
        followTitle: "Suivez le compte X et rejoignez le canal de discussion",
    },
    activity: {
        tap: "Commencer à tirer",
        comingsoon: "Attendez-vous à plus d'activités",
        use100: "Utilisez 100 $Daily pour tirer",
        dice: {
            rule1: "1. Chance de tirage au sort quotidienne : obtenez une chance initiale après avoir terminé l'enregistrement quotidien. Chaque tirage coûtera 100 $Daily",
            rule2: "2. Interdiction de tricher : l'utilisation de robots ou la triche est strictement interdite, les contrevenants seront disqualifiés",
        }
    },
    Profile: {
        Copylink: "Copier le lien",
        Share: "Partager",
        EarnReward: "Gagner des récompenses",
        Recommend: "Recommander",
        Invite: "Inviter des amis",
        Link: "Lien",
        Statistics: "Données d'invitation",
        FirstReferral: "Invitation de premier niveau",
        SecondReferral: "Invitation de second niveau",
        CheckInReward: "Récompense de connexion quotidienne :",
        ReferralReward: "Récompense de parrainage :",
        DearUser: "Cher @{username}",
    },
    Setting: {
        Version: "Version de Daily Wallet",
        Language: "Langue",
        Support: "Obtenir de l'aide",
        ChooseLanguage: "Choisissez votre langue",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Runssian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    Community: {
        Title: "Communauté",
        Announcement: "Annonces de Daily Wallet",
        Channel: "Canal de discussion Telegram",
        Twitter: "Compte officiel X",
        Whitepaper: "Whitepaper",
        Support: "Obtenir de l'aide",
    },
    Common: {
        PleaseOpenInTelegram: "Veuillez ouvrir dans Telegram",
        PleaseOpenPerDay: "Vous pouvez seulement participer au tirage au sort une fois par jour",
        PleaseOpenBalance: "Votre solde est insuffisant, inférieur à 100 $Daily",
        PleaseOpenfirst: "Veuillez d'abord réclamer votre récompense de connexion quotidienne",
        Congratulations: "Félicitations ! Vous avez gagné",
        Tomorrow: "$Daily ! Essayez à nouveau votre chance demain !",
        Hours: "Vous avez déjà réclamé votre récompense de connexion quotidienne dans les 24 heures, veuillez réessayer plus tard",
        Successfully: "Vous avez réussi à réclamer votre récompense de connexion quotidienne !",
        Twitter: "Votre compte X a été vérifié, 800 $Daily ont été envoyés à votre portefeuille",
        Verify: "Vérifier",
        TGChatChannel: "Rejoignez notre canal de discussion TG pour obtenir 500 $Daily",
        JoinedOurChat: "Vous avez rejoint notre canal de discussion, 500 $Daily ont été envoyés à votre portefeuille",
        VerificationSuccessful: "Vérification réussie",
        ToComplete: "Pour compléter la vérification, veuillez d'abord rejoindre notre groupe de discussion",
        TheVerification: "Cette vérification sera complétée dans 24 heures",
        ProgramReferral: "Règles du programme de parrainage",
        HowMany: "Combien puis-je obtenir à travers le programme de parrainage ?",
        Daily: "$Daily !",
        WhentheUsers: "Lorsque vos invités réclament leur récompense, vous obtiendrez",
        DailyActivity: "Récompense d'activité quotidienne : lorsque vos amis invités réclament leur récompense quotidienne, vous obtiendrez",
        DailyFor: "Pour chaque recommandation $Daily !",
        SecondLevel: "Récompense de deuxième niveau : lorsque la personne invitée invite d'autres personnes à s'inscrire à Daily Wallet, vous obtiendrez une récompense",
        FirstLevel: "Récompense de premier niveau : lorsque quelqu'un active un compte Daily Wallet avec votre lien de parrainage, vous recevrez une récompense",
        CopySuccessful: "Copie réussie",
        RefreshFailed: "Échec de l'actualisation",
    },
    Errors: {
        TryAgain: "Erreur ! Veuillez réessayer plus tard",
        TwitterSubmitted: "Le nom de votre compte X a déjà été enregistré",
        InputTwitter: "Veuillez entrer le nom de compte X correct, commençant par '@'"
    }

}