<template>
  <div>
    <div class="index">
      <tabBar :current="'0'"></tabBar>
      <div class="container">
        <div class="container_top">
          <div class="top_content">
            <div class="logo">
              <img src="../../assets/image/text.png" mode="scaleToFill"/>
            </div>
            <div class="setup" @click="toPath('setup')">
              <img src="../../assets/image/bell.png" mode="scaleToFill"/>
            </div>
          </div>
          <div class="container_content">
            <div class="content_item">
              <div class="left">{{ $t('wallet.totalBalance') }}</div>
              <div class="right">
                <img src="../../assets/image/statistics.png" mode="scaleToFill"/>
              </div>
            </div>
            <div class="text_item">
              <div class="text">{{ `$${balance.balance}` }}</div>
              <div class="right">
                <img src="../../assets/image/arrow-up.png" mode="scaleToFill" class="right_img"/>Daily
              </div>
            </div>

            <div class="bottom_content">
              <div class="item" @click="sendTokens">
                <img src="../../assets/svg/01.svg" mode="scaleToFill"/>
                <div>{{ $t('wallet.send') }}</div>
              </div>
              <div class="item">
                <img src="../../assets/svg/02.svg" mode="scaleToFill"/>
                <div>{{ $t('wallet.swap') }}</div>
              </div>
              <div class="item">
                <img src="../../assets/svg/03.svg" mode="scaleToFill"/>
                <div>{{ $t('wallet.deposit') }}</div>
              </div>
              <div class="item">
                <img src="../../assets/svg/04.svg" mode="scaleToFill"/>
                <div class="item_text">{{ $t('wallet.more') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container_tab">
        <div class="left" @click="showClaim">
          <div class="text">{{ $t('wallet.claimReward') }}</div>
          <img src="../../assets/image/left.png" mode="scaleToFill"/>
        </div>
        <div class="left right" @click="toPath('turntable')">
          <div class="text">{{ $t('wallet.DrawReward') }}</div>
          <img src="../../assets/image/right.png" mode="scaleToFill"/>
        </div>
      </div>
      <div class="container_bottom">
        <div class="content_b">
          <div class="b_title">{{ $t('wallet.wayTitle') }}</div>
          <div class="item" @click="toPath('ProfileIndex')">
            <img src="../../assets/svg/07.svg" mode="scaleToFill" class="icon"/>
            <div class="title">{{ $t('wallet.inviteTitle') }}</div>
            <img src="../../assets/image/right_j.png" mode="scaleToFill" class="icon_right"/>
          </div>
          <div class="item" @click="toPath('settings')">
            <img src="../../assets/svg/08.svg" mode="scaleToFill" class="icon"/>
            <div class="title">{{ $t('wallet.followTitle') }}</div>
            <img src="../../assets/image/right_j.png" mode="scaleToFill" class="icon_right"/>
          </div>
        </div>
      </div>
      <van-popup v-model="show" position="center">
        <div class="loding">
          <div class="loding_top">
            <img src="../../assets/image/notification_icon.png" mode="scaleToFill" class="loding_icon"/>
            <img src="../../assets/image/err.png" mode="scaleToFill" class="loding_icons" @click="show = false"
                 style="margin-right: 10px"/>
          </div>
          <div class="loding_content">
            <img src="../../assets/image/time.png" mode="scaleToFill"/>
            <div class="content_text">
              {{ $t('common.hours') }}
            </div>
          </div>
          <div class="loding_time">
            <div class="div1">
              <div class="title">{{ hours }}</div>
              <span>h</span>
            </div>
            <div class="div1">
              <div class="title">{{ minutes }}</div>
              <span>m</span>
            </div>
            <div class="div1">
              <div class="title">{{ seconds }}</div>
              <span>s</span>
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showreCaptha" position="center">
        <vue-turnstile @verified="token = $event" site-key="0x4AAAAAAAO05i85EqisH-pW"/>
      </van-popup>
      <van-popup v-model="showpopup" position="center">
        <div class="popup-bag">
          <img src="../../assets/image/rabbit.png" alt=""/>
          <div class="popup-text">
            {{ $t('common.successfully') }}
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import tabBar from '@/components/tabbar.vue'
import VueTurnstile from '@gaviti/vue-turnstile'
import request from '@/request'
import {Toast} from 'vant'

export default {
  components: {
    tabBar,
    VueTurnstile,
  },
  computed: {
    balance() {
      return this.$store.state.balance
    },
    accessToken() {
      return this.$store.state.authToken
    },
    endTime() {
      if (!this.$store.state.user && !this.$store.state.user.lastCheckIn) {
        return 0
      }
      return (this.$store.state.user.lastCheckIn + 24 * 60 * 60) * 1000
    },
    canCheckin() {
      if (!this.$store.state.user && !this.$store.state.user.lastCheckIn) {
        return false
      }
      return (
          this.$store.state.user.lastCheckIn + 24 * 60 * 60 < this.getTimestamp()
      )
    },
  },
  data() {
    return {
      show: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      token: null,
      showreCaptha: false,
      showpopup: false,
    }
  },
  created() {
  },
  mounted() {
    setInterval(() => {
      let now = new Date()
      let end = new Date(this.endTime)
      let difference = end - now
      if (difference < 0) {
        this.hours = 0
        this.minutes = 0
        this.seconds = 0
      } else {
        this.hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((difference % (1000 * 60)) / 1000)
      }
    }, 1000)
  },
  methods: {
    toPath(item) {
      this.$router.push({name: item})
    },
    sendTokens() {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.isClosingConfirmationEnabled = false;
        window.Telegram.WebApp.openTelegramLink('https://t.me/DailyWalletbot?start=send')
      }
    },
    showClaim() {
      if (!this.canCheckin) {
        this.show = true
        return
      }
      if (!this.token) {
        this.showreCaptha = true
      } else {
        this.checkin()
      }
    },
    async checkin() {
      try {
        await request.post(
            '/wallet/checkin',
            {
              token: this.token,
            },
            {
              headers: {
                authorization: `Bearer ${this.accessToken}`,
              },
            }
        )
      } catch (e) {
        Toast(this.$t('errors.tryAgain'))
      }
      this.showpopup = true
      const user = this.$store.state.user
      user.lastCheckIn = this.getTimestamp()
      const balance = this.$store.state.balance
      balance.balance = balance.balance + 100
      balance.check_amount = balance.check_amount + 1
      balance.checkinBalance = balance.checkinBalance + 100
      this.$store.commit('setBalance', balance)
      this.$store.commit('setUser', user)
    },
    getTimestamp() {
      return Math.floor(Date.now() / 1000)
    },
  },
  watch: {
    token() {
      this.showreCaptha = false
      this.checkin()
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .van-popup {
  background-color: transparent !important;
}

.index {
  min-height: 100vh;
  width: 100%;

  .container {
    .container_top {
      height: 328px;
      background: linear-gradient(180deg,
      #beabff 0%,
      rgba(255, 255, 255, 0) 100%);

      padding: 30px 13px;
      box-sizing: border-box;

      .top_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          width: 191px;
          display: flex;
          height: 49px;


          image {
            width: 100%;
            display: flex;
            height: 100%;
          }
        }

        .setup {
          width: 27px;
          height: 27px;

          image {
            width: 100%;
            height: 100%;
          }
        }
      }

      .container_content {
        margin: 35px auto 0;
        height: 191px;
        background: linear-gradient(48deg, #7242fb 0%, #9671ff 100%);
        border-radius: 20px;
        box-sizing: border-box;
        padding: 20px 11px 11px 11px;

        .content_item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            color: #f1f5f9;
            font-size: 14px;
            font-weight: 600;
          }

          .right {
            width: 25px;
            height: 25px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .text_item {
          margin-top: 6px;
          display: flex;
          align-items: center;

          .text {
            font-size: 34px;
            font-weight: 800;
            color: #ffffff;
            flex: 1;
          }

          .right {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            margin-right: 15px;

            .right_img {
              width: 10px;
              height: 10px;
            }
          }
        }

        .bottom_content {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 12px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          height: 75px;
          color: #ffffff;
          font-size: 14px;
          display: flex;
          margin-top: 10px;

          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            flex: 1;

            img {
              width: 29px;
              height: 29px;
            }

            div {
              font-size: 14px;
            }

            .item_text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .container_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 15px;
    margin-bottom: 5px;

    .left {
      width: 165px;
      height: 101px;
      background: #f8f5ff;
      border-radius: 12px;
      opacity: 1;
      border: 1px solid #ede5ff;
      display: flex;
      padding: 0 0 10px 10px;
      box-sizing: border-box;

      .text {
        width: 96px;
        height: 51px;
        font-size: 15px;
        font-family: Manrope, Manrope;
        color: #633ec2;
        margin-top: 25px;
      }

      img {
        width: 50px;
        height: 50px;
      }
    }

    .right {
      width: 165px;
      height: 101px;
      background: #fffaee;
      border-radius: 12px;
      opacity: 1;
      border: 1px solid #fff3d9;
      display: flex;

      .text {
        width: 115px;
        height: 51px;
        font-size: 15px;
        font-family: Manrope, Manrope;
        color: #ffad00;
        line-height: 22px;
        box-sizing: border-box;
      }

      img {
        width: 50px;
        height: 50px;
        margin: 10px 10px 0 0;
      }
    }
  }

  .container_bottom {
    height: 358px;
    background: linear-gradient(180deg, #f6f5f8 0%, #efe8f9 100%);

    .content_b {
      margin: 10px auto 0;
      width: 350px;
      height: 151px;
      background: #f8fafc;
      border-radius: 12px;
      opacity: 1;
      box-sizing: border-box;
      padding: 13px;

      .b_title {
        font-size: 14px;
        font-weight: 800;
        color: #10192d;
        line-height: 21px;
        border-bottom: 1px solid #ebebeb;
        line-height: 40px;
        height: 40px;
      }

      .item {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        .icon {
          width: 15px;
          height: 15px;
        }

        .title {
          flex: 1;
          width: 150px;
          font-size: 13px;
          font-weight: 500;
          color: #10192d;
          margin-left: 5px;
        }

        .icon_right {
          width: 5px;
          height: 10px;
        }
      }
    }
  }

  .loding {
    background: url('../../assets/image/notification.png');
    background-size: 100% 100%;
    width: 278px;
    height: 268px;

    .loding_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .loding_icon {
        width: 24px;
        height: 21px;
        margin: 13px 0 0 11px;
      }

      .loding_icons {
        width: 16px;
        height: 16px;
        margin: 16px 16px 0 0rpx;
      }
    }

    .loding_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 10px;

      image {
        width: 82px;
        height: 79px;
      }

      .content_text {
        width: 232px;
        height: 54px;
        font-size: 13px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        color: #000000;
        line-height: 15px;
        margin: 5px auto 10px;
        text-align: center;
      }
    }

    .loding_time {
      margin: 0 auto;
      width: 230px;
      height: 54px;
      background: #f8f8f8;
      border-radius: 6px;
      border: 1px solid #f2f2f4;
      display: flex;
      align-items: baseline;
      box-sizing: border-box;
      justify-content: space-between;
      padding: 0 10px;
      padding-top: 5px;

      .div1 {
        display: flex;
        align-items: baseline;

        span {
          margin-left: 4px;
          font-weight: 600;
          font-size: 16px;
        }

        .title {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
  }
}

.popup-bag {
  width: 228px;
  height: 201px;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  box-sizing: border-box;
  padding-top: 8px;
  flex-direction: column;

  img {
    margin: 0 auto;
    width: 91px;
    height: 127px;
  }

  .popup-text {
    font-size: 13px;
    font-family: Inter, Inter;
    font-weight: 400;
    color: #000000;
    line-height: 15px;
    height: 32px;
    margin: 0 auto;
    margin-top: 12px;
    text-align: center;
  }
}
</style>
