import Vue from "vue";
import App from "./App.vue";
import "amfe-flexible";
import Vant from "vant";
import "vant/lib/index.css";
import VueI18n from 'vue-i18n'
import VueLuckyCanvas from "@lucky-canvas/vue";
import router from "./router";
import {i18n} from "@/langs";
import {store} from "@/store";
import lottie from "vue-lottie";
import VueClipboard from 'vue-clipboard2'


Vue.component("lottie", lottie);


Vue.config.productionTip = false;
Vue.use(VueLuckyCanvas);
Vue.use(Vant);
Vue.use(VueI18n)
Vue.use(VueClipboard)


new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
