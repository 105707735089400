<template>
  <div class="index">
    <div class="top">
      <div class="title">
        Settings <br/>
        page
      </div>
      <img class="logo" src="../../../assets/image/Earth.png" alt=""/>
    </div>
    <div class="index_setup">
      <div class="wallet_Language">
        <div class="top">
          <div>{{ $t('setting.version') }}</div>
          <div>Beta Test</div>
        </div>
        <div class="bottom">
          <div>{{ $t('setting.language') }}</div>
          <div @click="goMultilingual">
            <img src="../../../assets/image/right_j.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="contact">
        <div>{{ $t('community.support') }}</div>
        <div @click="support"><img src="../../../assets/image/right_j.png" alt=""/></div>
      </div>
      <div class="list_title">{{ $t('community.title') }}</div>
      <div
          class="list_items"
          v-for="(item, index) in list"
          :key="index"
          @click="goIndex(index)"
      >
        <div class="icons"><img :src="item.src" alt=""/></div>
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          name: this.$t('community.announcement'),
          src: require('../../../assets/image/05.png'),
        },
        {
          name: this.$t('community.channel'),
          src: require('../../../assets/image/06.png'),
        },
        {
          name: this.$t('community.twitter'),
          src: require('../../../assets/image/07.png'),
        },
        {
          name: this.$t('community.whitepaper'),
          src: require('../../../assets/image/08.png'),
        },
      ],
    }
  },
  methods: {
    goMultilingual() {
      this.$router.push('./multilingual')
    },
    support() {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.isClosingConfirmationEnabled = false;
        window.Telegram.WebApp.openTelegramLink('https://t.me/Dailywallet2023')
      }
    },
    goIndex(index) {
      if (index === 0) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.isClosingConfirmationEnabled = false;
          window.Telegram.WebApp.openTelegramLink('https://t.me/DailyWalletNews')
        }
      } else if (index === 1) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.isClosingConfirmationEnabled = false;
          window.Telegram.WebApp.openTelegramLink('https://t.me/Dailywallet2023')
        }
      } else if (index === 2) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.isClosingConfirmationEnabled = false;
          window.Telegram.WebApp.openLink('https://twitter.com/dailywalletTG')
        }
      } else if (index === 3) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.isClosingConfirmationEnabled = false;
          window.Telegram.WebApp.openLink('https://daily-wallet.gitbook.io/daily-wallet-whitepaper/')
        }
      }
    }
    ,
  },
  mounted() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show()
      window.Telegram.WebApp.BackButton.onClick(() => {
        this.$router.back()
      })
    }
  },
  destroyed() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide()
    }
  },
}
</script>

<style lang="less" scoped>
.index {
  padding-top: 50px;
  height: 100%;
  background: url('../../../assets/image/new.png');

  .top {
    display: flex;

    .title {
      font-size: 34px;
      font-family: TencentSans, TencentSans;
      color: #000000;
      line-height: 44px;
      font-style: italic;
      font-weight: 600;
      margin-left: 28px;
    }
  }

  .index_setup {
    margin-top: -2px;
    height: 583px;
    background: #ffffff;
    border-radius: 19px 19px 19px 19px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    box-sizing: border-box;
    padding: 30px 21px 0 21px;

    .wallet_Language {
      width: 333px;
      height: 87px;
      background: #f8f8f8;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #f2f2f4;
      padding: 15px 16px 0 16px;
      box-sizing: border-box;

      .top {
        margin-bottom: 15px;
      }

      .bottom,
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div:first-child {
          font-size: 15px;
          font-family: PingFang TC, PingFang TC;
          font-weight: 600;
          color: #000000;
        }

        & > div:last-child {
          font-size: 13px;
          font-family: PingFang TC, PingFang TC;
          font-weight: 400;
          color: #727b7b;
          margin-top: 4px;
        }
      }
    }

    .contact {
      font-weight: 600;
      font-size: 15px;
      box-sizing: border-box;
      width: 333px;
      height: 51px;
      background: #f8f8f8;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #f2f2f4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 17px 0 16px;
      margin-top: 14px;
    }

    .list_title {
      font-size: 18px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 500;
      color: #000000;
      line-height: 21px;
      margin-top: 24px;
    }

    .list_items {
      margin-top: 14px;
      padding: 0 0 0 20px;
      box-sizing: border-box;
      width: 335px;
      height: 58px;
      background: #f8f8f8;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #f2f2f4;
      display: flex;
      align-items: center;

      .icons {
        margin-right: 14px;
        margin-top: 3px;
      }

      .text {
        vertical-align: middle;
        font-size: 14px;
        font-family: TencentSans, TencentSans;
        font-weight: 600;
        color: #000000;
      }
    }
  }
}
</style>
