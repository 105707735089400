<template>
  <div>
    <tabBar :current="'1'"></tabBar>
    <div class="container">
      <div class="heander">
        <img src="../../assets/image/text.png" mode="scaleToFill"/>
      </div>
      <div class="box">
        <div class="box-main">
          <img class="line" src="../../assets/image/line.png" mode=""/>
          <div class="text_title">{{ $t('activity.use100') }}</div>
          <div class="text_new">
            <div style="color: #8d65ff; margin: 0 10rpx">10,000</div>
            $Daily
          </div>
          <div class="btn" @click="toPath">{{ $t('activity.tap') }}</div>
        </div>
        <img class="diamond" src="../../assets/image/diamond.png" mode=""/>
        <div class="text">{{ $t('activity.comingsoon') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import tabBar from '@/components/tabbar.vue'

export default {
  components: {tabBar},
  data() {
    return {}
  },
  methods: {
    toPath() {
      this.$router.push("./turntable");
    },
  },
}
</script>

<style scoped lang="less">
.container {
  min-height: 100vh;
  background-image: url('../../assets/image/activityba.png');
  background-size: 100% 100%;
  padding: 30px 0;
  box-sizing: border-box;
}

.heander {
  padding-left: 13px;
}

.box {
  // position: absolute;
  // bottom: 0;
  margin-top: 140px;
  width: 100%;
  height: 561px;
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(248, 248, 248, 0) 100%);
  border-radius: 30px 30px 0px 0px;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  .diamond {
    width: 68px;
    height: 107px;
    margin-top: 50px;
  }

  .text {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 400;
    color: #828589;
  }

  .box-main {
    width: 337px;
    height: 220px;
    background: #ffffff;
    border-radius: 24px 24px 12px 12px;
    opacity: 1;

    .line {
      position: absolute;
      top: 17px;
      width: 220px;
      height: 66px;
    }

    .text_title {
      margin: 0 auto;
      margin-top: 40px;
      padding-left: 24px;
      font-size: 20px;
      font-family: TencentSans, TencentSans;
      font-weight: 600;
      color: #1a1a1a;
      font-style: italic;
    }

    .text_new {
      width: 283px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 26px;
      font-family: TencentSans, TencentSans;
      font-weight: 600;
      color: #060606;
      display: flex;
      font-style: italic;
    }

    .btn {
      width: 283px;
      height: 44px;
      background: #000000;
      border-radius: 24px;
      opacity: 1;
      margin: 40px auto;
      margin-bottom: 0;
      font-size: 16px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
