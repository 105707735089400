export const es = {
    bar: {
        wallet: 'Cartera',
        activity: 'Actividad',
        profile: "Perfil",
    },
    wallet: {
        totalBalance: "Saldo total de $Daily",
        send: "Enviar",
        swap: "Intercambio",
        deposit: "Depósito",
        more: "Más activos",
        claimReward: "Reclama tu recompensa diaria",
        DrawReward: "Gana más $Daily",
        wayTitle: "Formas de obtener más $Daily",
        inviteTitle: "Invita a tus amigos a unirse",
        followTitle: "Sigue la cuenta X y únete al canal de chat",
    },
    activity: {
        tap: "Toca aquí",
        comingsoon: "Más próximamente",
        use100: "Sorteo con 100 $Daily!",
        dice: {
            rule1: "1. Oportunidad diaria de sorteo de suerte: Obtén una oportunidad cruda cada día después de completar tu registro diario. Cada sorteo costará 100 $Daily.",
            rule2: "2. No hacer trampa: Se prohíbe estrictamente el uso de bots o hacer trampa, lo que conduce a la descalificación",
        }
    },
    profile: {
        copylink: "Copiar enlace",
        share: "Compartir",
        earnReward: "Ganar recompensas",
        recommend: "Recomendar",
        invite: "Invitar amigos",
        link: "Enlace",
        statistics: "Estadísticas",
        firstReferral: "Referido de primer nivel",
        secondReferral: "Referido de segundo nivel",
        checkInReward: "Recompensa diaria de registro:",
        referralReward: "Recompensa por referido:",
        dearUser: "Estimado @{username}",
    },
    setting: {
        version: "Versión de Daily Wallet",
        language: "Idioma",
        support: "Contactar soporte",
        chooseLanguage: "Elige tu idioma",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Comunidad",
        announcement: "Anuncio de Daily Wallet",
        channel: "Canal de chat en Telegram",
        twitter: "Cuenta oficial X",
        whitepaper: "Whitepaper",
        support: "Consigue ayuda",
    },
    common: {
        pleaseOpenInTelegram: "Por favor, abre en Telegram",
        pleaseOpenPerDay: "Solo puedes girar una vez al día",
        pleaseOpenBalance: "Saldo insuficiente",
        PleaseOpenfirst: "Primero, reclame sus recompensas de inicio de sesión diarias",
        Congratulations: "¡Felicidades! Has ganado",
        tomorrow: "¡$Daily! Intenta tu buena suerte de nuevo mañana",
        hours: "Has reclamado la recompensa de check-in diario dentro de las 24 horas, por favor, intenta de nuevo después",
        successfully: "¡Has reclamado con éxito la recompensa de check-in diario!",
        Twitter: "Tu cuenta de X ha sido verificada, se han enviado 800 $Daily a tu cartera",
        verify: "verificar",
        TGChatChannel: "Únete a nuestro Canal de Chat TG para ganar 500 $Daily",
        joinedOurChat: "Te has unido a nuestro canal de chat, se han enviado 500 $Daily a tu cartera",
        VerificationSuccessful: "Verificación exitosa",
        ToComplete: "Para completar la verificación, por favor únete primero a nuestro grupo de chat",
        TheVerification: "La verificación tomará 24 horas en completarse",
        ProgramReferral: "Regla del Programa de Referencia",
        HowMany: "¿Cuántos $Daily puedo obtener por el programa de referidos?",
        Daily: "¡$Daily!",
        WhentheUsers: "Cuando los usuarios traídos por tus invitaciones reclamen sus recompensas, recibirás",
        DailyActivity: "Recompensa de Actividad Diaria: Cuando tus amigos invitados reclamen sus recompensas diarias, recibirás",
        DailyFor: "$Daily por cada referido!",
        SecondLevel: "Recompensa de segundo nivel: Cuando la persona invitada refiere a otros para registrarse en Daily Wallet, ganarás una recompensa de",
        FirstLevel: "Recompensa de Primer Nivel: Cuando alguien activa una cuenta de Daily Wallet usando tu enlace de referido, recibirás una recompensa de",
        CopySuccessful: "Copia exitosa",
        RefreshFailed: "Actualización fallida",
    },
    errors: {
        tryAgain: "¡error! Por favor, inténtelo de nuevo más tarde",
        twitterSubmitted: "El nombre de tu cuenta X ha sido guardado",
        inputTwitter: "Ingrese el nombre de cuenta X correcto, precedido por '@'."
    }
}