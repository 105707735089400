export const ind = {
    bar: {
        wallet: 'Dompet',
        activity: 'Aktivitas',
        profile: "Profil",
    },
    wallet: {
        totalBalance: "Saldo Total $Daily",
        send: "Kirim",
        swap: "Tukar",
        deposit: "Deposit",
        more: "Lebih Banyak Aset",
        claimReward: "Klaim Hadiah Harian Anda",
        DrawReward: "Tarik Lebih Banyak $Daily",
        wayTitle: "Cara Mendapatkan Lebih Banyak $Daily",
        inviteTitle: "Undang Teman Anda untuk Bergabung",
        followTitle: "Ikuti Akun X dan Bergabung dengan Saluran Obrolan",
    },
    activity: {
        tap: "Ketuk Di Sini",
        comingsoon: "Segera Hadir Lebih Banyak",
        use100: "Gambarlah dengan 100 $Daily Hari",
        dice: {
            rule1: "1. Kesempatan Undian Keberuntungan Harian: Dapatkan satu kesempatan mentah setiap hari setelah menyelesaikan check-in harian Anda. Setiap undian akan menghabiskan 100 $Daily.",
            rule2: "2. Tidak Ada Kecurangan: Penggunaan bot atau kecurangan dilarang keras dan mengakibatkan diskualifikasi",
        }
    },
    profile: {
        copylink: "Salin Tautan",
        share: "Bagikan",
        earnReward: "Dapatkan Hadiah",
        recommend: "Rekomendasikan",
        invite: "Undang Teman",
        link: "Tautan",
        statistics: "Statistik",
        firstReferral: "Referral Tingkat Pertama",
        secondReferral: "Referral Tingkat Kedua",
        checkInReward: "Hadiah Check-in Harian:",
        referralReward: "Hadiah Referral:",
        dearUser: "Yang terhormat @{username}",
    },
    setting: {
        version: "Versi Daily Wallet",
        language: "Bahasa",
        support: "Hubungi Dukungan",
        chooseLanguage: "Pilih Bahasa Anda",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Komunitas",
        announcement: "Pengumuman Daily Wallet",
        channel: "Saluran Obrolan Telegram",
        twitter: "Akun Resmi X",
        whitepaper: "Whitepaper",
        support: "Mendapatkan bantuan",
    },
    common: {
        pleaseOpenInTelegram: "Silakan buka di Telegram",
        pleaseOpenPerDay: "Anda hanya dapat melakukan roll sekali per hari",
        pleaseOpenBalance: "Saldo tidak cukup",
        PleaseOpenfirst: "Silakan klaim hadiah masuk harian Anda terlebih dahulu",
        Congratulations: "Selamat! Anda menang",
        tomorrow: "$Daily! Coba keberuntungan Anda lagi besok!",
        hours: "Anda telah mengklaim hadiah check-in harian dalam 24 jam, silakan coba lagi setelah itu",
        successfully: "Anda telah berhasil mengklaim hadiah check-in harian!",
        Twitter: "Akun X Anda telah diverifikasi, 800 $Daily telah dikirim ke dompet Anda",
        verify: "verifikasi",
        TGChatChannel: "Bergabunglah dengan Saluran Chat TG kami untuk mendapatkan 500 $Daily",
        joinedOurChat: "Anda telah bergabung dengan saluran chat kami, 500 $Daily telah dikirim ke dompet Anda",
        VerificationSuccessful: "Verifikasi berhasil",
        ToComplete: "Untuk menyelesaikan verifikasi, silakan bergabung dengan grup chat kami terlebih dahulu",
        TheVerification: "Verifikasi akan memakan waktu 24 jam untuk selesai",
        ProgramReferral: "Aturan Program Referral",
        HowMany: "Berapa banyak $Daily yang bisa saya dapatkan melalui program referral?",
        Daily: "$Daily!",
        WhentheUsers: "Ketika pengguna yang Anda undang mengklaim hadiah mereka, Anda akan mendapatkan",
        DailyActivity: "Hadiah Aktivitas Harian: Ketika teman yang Anda undang mengklaim hadiah harian mereka, Anda akan menerima",
        DailyFor: "$Daily untuk setiap referral!",
        SecondLevel: "Hadiah level kedua: Ketika orang yang diundang merujuk orang lain untuk mendaftar Daily Wallet, Anda akan mendapatkan hadiah",
        FirstLevel: "Hadiah Level Pertama: Ketika seseorang mengaktifkan akun Daily Wallet dengan menggunakan tautan referral Anda, Anda akan menerima hadiah",
        CopySuccessful: "Salin berhasil",
        RefreshFailed: "Pembaruan gagal",
    },
    errors: {
        tryAgain: "kesalahan! Silakan coba lagi nanti",
        twitterSubmitted: "Nama akun X Anda telah disimpan",
        inputTwitter: "Silakan masukkan nama akun X yang benar, dengan '@' di depannya"
    }
}
