<template>
  <div class="tabbar">
    <div
        class="tabbar-item"
        v-for="(item, index) in list"
        :key="index"
        @click="tabbarChange(item, index)"
    >
      <div class="item" v-if="current == index">
        <img class="item_img" :src="item.iconPath"/>
        <span class="item_text_active">{{ item.text }}</span>
      </div>
      <div class="item" v-else>
        <img class="item_img" :src="item.icon"/>
        <span class="item_text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: String,
  },
  data() {
    return {
      indexbj: null,
      list: [
        {
          id: 0,
          text: this.$t("bar.wallet"),
          route: "Wallet",
          icon: require("/src/assets/image/wallet.png"),
          iconPath: require("/src/assets/image/wallet-active.png"),
          path: "../views/Wallet/index.vue",
        },
        {
          id: 1,
          route: "Activity",
          text: this.$t("bar.activity"),
          icon: require("/src/assets/image/Group.png"),
          iconPath: require("/src/assets/image/Group-active.png"),
          path: "../views/Activity/index.vue",
        },
        {
          id: 2,
          text: this.$t("bar.profile"),
          route: "Profile",
          icon: require("/src/assets/image/category.png"),
          iconPath: require("/src/assets/image/category-active.png"),
          path: "../views/Profile/index.vue",
        },
      ],
    };
  },
  created() {
  },
  methods: {
    tabbarChange(item, index) {
      this.indexbj = index;
      if (this.current == item.id) {
        return;
      }
      this.$router.push({name: item.route});
    },
  },
};
</script>

<style scoped lang="less">
.tabbarActive {
  color: #79d5ad !important;
}

.tabbar {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  right: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 341px;
  height: 52px;
  background: #f8fafc;
  border-radius: 12px;
  opacity: 1;
  z-index: 99;
  border: 1px solid #f8fafc;
  box-shadow: 0px 8px 12px rgba(96, 96, 96, 0.1),
  0px 1px 3px rgba(92, 92, 92, 0.08);

  .item {
    display: flex;
    align-items: center;

    .item_img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .item_text {
      font-size: 11px;
      color: #8e9bae;
      font-weight: 600;
    }

    .item_text_active {
      font-size: 11px;
      font-weight: 600;
      color: #6728ff;
    }
  }
}
</style>
