<template>
  <div>
    <div class="index">
      <div class="title">
        <img src="../../../assets/image/15.png" mode="scaleToFill"/>
      </div>
      <div class="content">
        <LuckyWheel
            class="lucky-wheel"
            ref="myLucky"
            width="335px"
            height="335px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
        >
        </LuckyWheel>
      </div>

      <div class="bottom">
        <img src="../../../assets/image/bottom_d.png" mode="scaleToFill"/>
      </div>

      <div class="bottom_img">
        <img src="../../../assets/image/16.png" mode="scaleToFill"/>
      </div>

      <div class="list_item">
        <div style="margin-bottom: 25px">
          {{ $t('activity.dice.rule1') }}
        </div>
        <div>
          {{ $t('activity.dice.rule2') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/request'
import {Toast} from 'vant'

export default {
  data() {
    return {
      token: null,
      blocks: [
        {
          padding: '35px',
          imgs: [
            {
              top: '1%',
              width: '100%',
              height: '100%',
              src: require('/src/assets/image/bor.png'),
              rotate: true,
            },
          ],
        },
      ],
      prizes: [
        {
          fonts: [{text: '50', top: '10%', fontColor: '#947600'}],
          background: '#FFE064',
        },
        {
          fonts: [{text: '100', top: '10%', fontColor: '#947600'}],
          background: '#FFFFFD',
        },
        {
          fonts: [{text: '200', top: '10%', fontColor: '#947600'}],
          background: '#FFE064',
        },
        {
          fonts: [{text: '500', top: '10%', fontColor: '#947600'}],
          background: '#FFFFFD',
        },
        {
          fonts: [{text: '2000', top: '10%', fontColor: '#947600'}],
          background: '#FFE064',
        },
        {
          fonts: [{text: '10000', top: '10%', fontColor: '#947600'}],
          background: '#FFFFFD',
        },
      ],
      buttons: [
        {
          radius: '35%',
          pointer: true,
          imgs: [
            {
              top: '-44px',
              width: '100%',
              height: '100%',
              src: require('/src/assets/image/go.png'),
              rotate: true,
            },
          ],
        },
      ],
      numIndex: 0,
      errCode: null,
      reward: null,
    }
  },
  computed: {
    accessToken() {
      return this.$store.state.authToken
    },
  },
  methods: {
    startCallback() {
      this.$refs.myLucky.play()
      this.checkNum()
      setTimeout(() => {
        const index = this.numIndex
        this.$refs.myLucky.stop(index)
        if (this.reward != null) {
          Toast(
              `${this.$t('common.Congratulations')} ${this.reward} ${this.$t(
                  'common.tomorrow'
              )}`
          )
        }
      }, 3000)
    },
    endCallback(prize) {
    },

    async checkNum() {
      let that = this
      try {
        const data = await request
            .post(
                '/wallet/roll',
                {
                  token: this.$store.state.authToken,
                },
                {
                  headers: {
                    authorization: `Bearer ${this.accessToken}`,
                  },
                }
            )
            .catch((err) => {
              if (
                  err.response.data.code == 100 ||
                  err.response.data.code == 101 ||
                  err.response.data.code == 102
              ) {
              } else {
                that.$refs.myLucky.play()
              }
              const errorMessages = {
                102: this.$t('common.pleaseOpenPerDay'),
                100: this.$t('common.pleaseOpenBalance'),
                101: this.$t('common.PleaseOpenfirst'),
              }

              for (const code in errorMessages) {
                if (err.response.data.code == code) {
                  Toast(errorMessages[code])
                  break
                }
              }
            })
        if (data.data.statusCode != 200) {
          return
        } else {
          const numNew = data.data.data.result
          this.reward = numNew

          const balance = this.$store.state.balance;
          balance.balance = balance.balance + numNew
          this.$store.commit('setBalance', balance)

          switch (numNew) {
            case 50:
              this.numIndex = 0
              break
            case 100:
              this.numIndex = 1
              break
            case 200:
              this.numIndex = 2
              break
            case 500:
              this.numIndex = 3
              break
            case 1000:
              this.numIndex = 4
              break
            case 2000:
              this.numIndex = 5
              break
            case 10000:
              this.numIndex = 6
              break
            default:
              break
          }

        }
      } catch (error) {
        console.error('Error in checkNum:', error)
      }
    },
  },
  mounted() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show()
      window.Telegram.WebApp.BackButton.onClick(() => {
        this.$router.back()
      })
    }
  },
  destroyed() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide()
    }
  },
}
</script>
<style scoped lang="less">
.index {
  background: url('../../../assets/image/bgcn.jpg');
  background-size: 100% 100%;
  min-height: 130vh;

  .title {
    padding-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    justify-content: center;
    position: relative;

    .lucky-wheel {
      position: absolute;
    }
  }

  .bottom {
    width: 252px;
    height: 110px;
    margin: 0 auto;
    margin-top: 260px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bottom_img {
    width: 194px;
    height: 13px;
    margin: 40px auto 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list_item {
    // position: fixed;
    // bottom: 0;
    font-size: 13px;
    color: #ffffff;
    background-color: #7542c5;
    width: 343px;
    height: 156px;
    margin: 15px auto 0;
    box-sizing: border-box;
    padding: 20px;

    div {
      width: 312px;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
</style>
