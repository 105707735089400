import Vue from 'vue'
import VueI18n from "vue-i18n";
import { zh } from "@/langs/zh";
import { en } from "@/langs/en";
import { ar } from "@/langs/ar";
import { es } from "@/langs/es";
import { ind } from "@/langs/ind";
import { jp } from "@/langs/jp";
import { kr } from "@/langs/kr";
import { pt } from "@/langs/pt";
import { tr } from "@/langs/tr";
import { vnm } from "@/langs/vnm";
import { bg } from "@/langs/bg";
import { fr } from "@/langs/fr";
import { rs } from "@/langs/rs";
import { india } from "@/langs/india";


Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: 'en',
    messages: {
        zh,
        en,
        ar,
        es,
        ind,
        jp,
        kr,
        pt,
        tr,
        vnm,
        bg,
        fr,
        rs,
        india
    }
})