export const pt = {
    bar: {
        wallet: 'Carteira',
        activity: 'Atividade',
        profile: "Perfil",
    },
    wallet: {
        totalBalance: "Saldo Total de $Daily",
        send: "Enviar",
        swap: "Trocar",
        deposit: "Depósito",
        more: "Mais Ativos",
        claimReward: "Reivindique sua Recompensa Diária",
        DrawReward: "Sorteio de Mais $Daily",
        wayTitle: "Formas de conseguir mais $Daily",
        inviteTitle: "Convide seus amigos para se juntar",
        followTitle: "Siga a conta X e participe do canal de chat",
    },
    activity: {
        tap: "Clique Aqui",
        comingsoon: "Mais novidades em breve",
        use100: "Sorteio com 100 $Daily",
        dice: {
            rule1: "1. Chance diária de sorteio: Ganhe uma chance por dia após completar seu check-in diário. Cada sorteio custará 100 $Daily.",
            rule2: "2. Sem trapaças: O uso de bots ou trapaças é estritamente proibido e leva à desqualificação",
        }
    },
    profile: {
        copylink: "Copiar link",
        share: "Compartilhar",
        earnReward: "Ganhar recompensas",
        recommend: "Recomendar",
        invite: "Convidar amigos",
        link: "Link",
        statistics: "Estatísticas",
        firstReferral: "Indicação de Primeiro Nível",
        secondReferral: "Indicação de Segundo Nível",
        checkInReward: "Recompensa de check-in diário:",
        referralReward: "Recompensa por indicação:",
        dearUser: "Prezado @{username}",
    },
    setting: {
        version: "Versão da Daily Wallet",
        language: "Idioma",
        support: "Contato com Suporte",
        chooseLanguage: "Escolha seu Idioma",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Comunidade",
        announcement: "Anúncio da Daily Wallet",
        channel: "Canal de Chat no Telegram",
        twitter: "Conta Oficial X",
        whitepaper: "Whitepaper",
        support: "Obter ajuda",
    },
    common: {
        pleaseOpenInTelegram: "Por favor, abra no Telegram",
        pleaseOpenPerDay: "Você só pode rolar uma vez por dia",
        pleaseOpenBalance: "Saldo insuficiente",
        PleaseOpenfirst: "Reivindique suas recompensas de login diário primeiro",
        Congratulations: "Parabéns! Você ganhou",
        tomorrow: "$Daily! Tente a sua sorte novamente amanhã!",
        hours: "Você já reivindicou a recompensa do check-in diário nas últimas 24 horas, tente novamente depois",
        successfully: "Você reivindicou com sucesso a recompensa do check-in diário!",
        Twitter: "Sua conta no X foi verificada, 800 $Daily foram enviados para a sua carteira",
        verify: "verificar",
        TGChatChannel: "Junte-se ao nosso Canal de Chat TG para ganhar 500 $Daily",
        joinedOurChat: "Você entrou no nosso canal de chat, 500 $Daily foram enviados para a sua carteira",
        VerificationSuccessful: "Verificação bem-sucedida",
        ToComplete: "Para completar a verificação, por favor, junte-se ao nosso grupo de chat primeiro",
        TheVerification: "A verificação levará 24 horas para ser concluída",
        ProgramReferral: "Regra do Programa de Referência",
        HowMany: "Quantos $Daily eu posso obter pelo programa de referência?",
        Daily: "$Daily!",
        WhentheUsers: "Quando os usuários trazidos pelos seus convites reivindicarem suas recompensas, você receberá",
        DailyActivity: "Recompensa de Atividade Diária: Quando seus amigos convidados reivindicarem suas recompensas diárias, você receberá",
        DailyFor: "$Daily por cada indicação!",
        SecondLevel: "Recompensa de segundo nível: Quando a pessoa convidada indica outras para se registrar no Daily Wallet, você ganhará uma recompensa de",
        FirstLevel: "Recompensa de Primeiro Nível: Quando alguém ativa uma conta no Daily Wallet usando o seu link de referência, você receberá uma recompensa de",
        CopySuccessful: "Cópia bem-sucedida",
        RefreshFailed: "Falha na atualização",
    },
    errors: {
        tryAgain: "Erro! Por favor, tente novamente mais tarde",
        twitterSubmitted: "O nome da sua conta X foi salvo",
        inputTwitter: "Insira o nome da conta X correto, com '@' na frente"
    }
}