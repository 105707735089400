export const tr = {
    bar: {
        wallet: 'Cüzdan',
        activity: 'Etkinlik',
        profile: "Profil",
    },
    wallet: {
        totalBalance: "$Daily Toplam Bakiye",
        send: "Gönder",
        swap: "Takas",
        deposit: "Yatırma",
        more: "Daha Fazla Varlık",
        claimReward: "Günlük Ödülünüzü Talep Edin",
        DrawReward: "Daha Fazla $Daily Kazan",
        wayTitle: "Daha Fazla $Daily Kazanmanın Yolları",
        inviteTitle: "Arkadaşlarınızı davet edin",
        followTitle: "X hesabını takip edin ve sohbet kanalına katılın",
    },
    activity: {
        tap: "Buraya Tıklayın",
        comingsoon: "Yakında daha fazlası",
        use100: "Çekiliş için 100 $Daily kullanın",
        dice: {
            rule1: "1. Günlük Şanslı Çekiliş Şansı: Günlük girişinizi tamamladıktan sonra her gün bir ham şans kazanın. Her çekiliş 100 $Daily mal olacak.",
            rule2: "2. Hile Yapmak Yasaktır: Bot kullanımı veya hile yapmak kesinlikle yasaktır ve diskalifiye olmaya yol açar",
        }
    },
    profile: {
        copylink: "Bağlantıyı Kopyala",
        share: "Paylaş",
        earnReward: "Ödüller Kazan",
        recommend: "Tavsiye Et",
        invite: "Arkadaşları Davet Et",
        link: "Bağlantı",
        statistics: "İstatistikler",
        firstReferral: "Birinci seviye davetçi",
        secondReferral: "İkinci seviye davetçi",
        checkInReward: "Günlük giriş ödülü:",
        referralReward: "Yönlendirme ödülü:",
        dearUser: "Sayın @{username}",
    },
    setting: {
        version: "Daily Wallet Sürümü",
        language: "Dil",
        support: "Destekle İletişim Kur",
        chooseLanguage: "Dilinizi Seçin",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Topluluk",
        announcement: "Daily Wallet Duyuru",
        channel: "Telegram Sohbet Kanalı",
        twitter: "Resmi X Hesabı",
        whitepaper: "Whitepaper",
        support: "Yardım almak",
    },
    common: {
        pleaseOpenInTelegram: "Lütfen Telegram'da açın",
        pleaseOpenPerDay: "Günde sadece bir kez çevirebilirsiniz",
        pleaseOpenBalance: "Yetersiz bakiye",
        PleaseOpenfirst: "Lütfen önce günlük oturum açma ödüllerinizi alın",
        Congratulations: "Tebrikler! Kazandınız",
        tomorrow: "$Daily! Şansınızı yarın tekrar deneyin!",
        hours: "Günlük check-in ödülünüzü 24 saat içinde talep ettiniz, lütfen sonra tekrar deneyin",
        successfully: "Günlük check-in ödülünü başarıyla talep ettiniz!",
        Twitter: "X hesabınız doğrulandı, cüzdanınıza 800 $Daily gönderildi",
        verify: "doğrula",
        TGChatChannel: "500 $Daily kazanmak için TG Sohbet Kanalımıza katılın",
        joinedOurChat: "Sohbet kanalımıza katıldınız, cüzdanınıza 500 $Daily gönderildi",
        VerificationSuccessful: "Doğrulama başarılı",
        ToComplete: "Doğrulamayı tamamlamak için lütfen önce sohbet grubumuza katılın",
        TheVerification: "Doğrulamanın tamamlanması 24 saat sürecektir",
        ProgramReferral: "Yönlendirme Programı Kuralı",
        HowMany: "Yönlendirme programıyla kaç $Daily kazanabilirim?",
        Daily: "$Daily!",
        WhentheUsers: "Davetlerinizle gelen kullanıcılar ödüllerini talep ettiğinde, siz alacaksınız",
        DailyActivity: "Günlük Aktivite Ödülü: Davet ettiğiniz arkadaşlarınız günlük ödüllerini talep ettiğinde, siz alacaksınız",
        DailyFor: "Her yönlendirme için $Daily!",
        SecondLevel: "İkinci seviye ödül: Davet edilen kişi başkalarını Daily Wallet'a kaydettirmeye yönlendirdiğinde, ödül kazanacaksınız",
        FirstLevel: "Birinci Seviye Ödül: Birisi yönlendirme bağlantınızı kullanarak Daily Wallet hesabını aktifleştirdiğinde, ödül alacaksınız",
        CopySuccessful: "Kopyalama başarılı",
        RefreshFailed: "Yenileme başarısız oldu",
    },
    errors: {
        tryAgain: "hata! Lütfen daha sonra tekrar deneyiniz",
        twitterSubmitted: "X hesap adınız kaydedildi",
        inputTwitter: "Lütfen önünde '@' işareti olacak şekilde doğru X hesap adını girin"
    }
}
