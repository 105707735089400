<template>
  <div class="invitation">
    <div class="Picture">
      <img src="../../../assets/image/hand.png" alt=""/>
    </div>
    <div class="search">
      <div class="text">
        <span @click="twitterFollow">Follow</span> and <span @click="twitterRetweet">Retweet</span> to gain 800 $Daily
        <span class="icon"
        ><img src="../../../assets/image/tte.png" alt=""
        /></span>
      </div>
      <div class="ipt">
        <input
            v-if="!twitterVerified"
            type="text"
            v-model="twitter_id"
            class="inputt"
            :placeholder="computedPlaceholder"
        />
        <button class="btn" v-if="!twitterVerified" @click="submitTwitter" :disabled="twitterAccount">
          {{ twitterAccount ? "Reviewing" : $t('common.verify') }}
        </button>
        <div class="text" v-if="twitterVerified">
          <img class="success" src="../../../assets/image/success.png" alt=""/>
          <div class="title">
            {{ $t('common.Twitter') }}
          </div>
        </div>
      </div>
    </div>
    <div class="channel">
      <div class="channel-text">
        {{ $t('common.TGChatChannel') }}
      </div>
      <div v-if="!channelVerified">
        <button class="btn" @click="joinChannel">{{ $t('common.verify') }}</button>
      </div>
      <div class="text" v-else>
        <img class="success" src="../../../assets/image/success.png" alt=""/>
        <div class="title">
          {{ $t('common.joinedOurChat') }}
        </div>
      </div>
    </div>
    <div class="tabarbg"></div>

    <!--    <van-popup v-model="showSessessPopup" position="center" closeable>-->
    <!--      <div class="popup-bag">-->
    <!--        <img src="../../../assets/image/rabbit.png" alt=""/>-->
    <!--        <div class="popup-text">{{ $t('common.VerificationSuccessful') }}</div>-->
    <!--      </div>-->
    <!--    </van-popup>-->

    <!--    <van-popup v-model="shwoErrorPopup" position="center" closeable>-->
    <!--      <div class="popup-bagNew">-->
    <!--        <img src="../../../assets/image/newError.png" alt=""/>-->
    <!--        <div class="popup-textNEW">-->
    <!--          {{ $t('common.ToComplete') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </van-popup>-->

    <van-popup v-model="shwoTimePopup" position="center" closeable>
      <div class="popup-bagNew">
        <img src="../../../assets/image/time.png" alt=""/>
        <div class="popup-textNEW">
          {{ $t('common.TheVerification') }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import service from "@/request";
import {Toast} from "vant";

export default {
  data() {
    return {
      isSuccess: false,
      shwoTimePopup: false,
      twitter_id: '',
    }
  },

  computed: {
    twitterVerified() {
      return this.$store.state.user.twitterVerified
    },
    twitterAccount() {
      return this.$store.state.user.twitter !== "";
    },
    computedPlaceholder() {
      return this.twitterAccount ? this.$store.state.user.twitter : "Input your X handler, e.g. @user1"
    },
    channelVerified() {
      return this.$store.state.user.groupRewardClaimed
    },
    accessToken() {
      return this.$store.state.authToken
    },
  },
  methods: {
    joinChannel() {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.isClosingConfirmationEnabled = false;
        window.Telegram.WebApp.openTelegramLink('https://t.me/DailyWalletbot?start=joinchannel')
      }
    },
    twitterFollow() {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.isClosingConfirmationEnabled = false;
        window.Telegram.WebApp.openLink('https://twitter.com/dailywalletTG')
      }
    },
    twitterRetweet() {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.isClosingConfirmationEnabled = false;
        window.Telegram.WebApp.openLink('https://twitter.com/dailywalletTG/status/1721031918786138477')
      }
    },
    async submitTwitter() {
      if (this.twitterAccount) {
        this.shwoTimePopup = true;
        return;
      }

      if (!this.twitter_id || !this.twitter_id.startsWith('@')) {
        Toast(this.$t("errors.inputTwitter"))
        return;
      }
      try {
        await service.post(
            '/users/twitter',
            {
              twitter: this.twitter_id
            }, {
              headers: {
                authorization: `Bearer ${this.accessToken}`
              }
            }
        )
        const user = this.$store.state.user
        user.twitter = this.twitter_id
        this.$store.commit('setUser', user)

        this.shwoTimePopup = true;

      } catch (e) {
        if (e.response.data.code === 100) {
          Toast(this.$t("errors.twitterSubmitted"))
        } else {
          Toast(this.$t("errors.tryAgain"))
        }
      }
    }
  }
  ,
  mounted() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.show()
      window.Telegram.WebApp.BackButton.onClick(() => {
        this.$router.back()
      })
    }
  }
  ,
  destroyed() {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.BackButton.hide()
    }
  }
  ,
}
</script>

<style lang="less" scoped>
.invitation {
  position: relative;
  background: url('../../../assets/image/new.png');
  background-size: 100% 100%;
  padding: 0 10px;

  .Picture {
    margin-left: 35px;

    img {
      width: 298px;
      height: 263px;
    }
  }

  .search {
    padding: 22px 11px 48px 23px;
    margin-top: -20px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #f2f2f2;

    .text {
      margin-left: 20px;
      width: 274px;
      font-size: 19px;
      text-align: center;
      color: #000000;
      box-sizing: border-box;
      font-weight: 600;

      .icon {
        vertical-align: middle;
      }

      span {
        color: #7717ea;
      }
    }

    .ipt {
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: #000000;
      }

      text-align: center;
      margin-top: 20px;

      input {
        width: 235px;
        height: 40px;
        background: #f8f8f8;
        border-radius: 6px 6px 6px 6px;
        padding: 0 20px;
        opacity: 1;
        border: 1px solid #dadbde;
        outline: none;
        font-size: 11px;
        box-sizing: border-box;
      }

      .btn {
        margin-left: 13px;
        margin-top: 20px;
        width: 71px;
        height: 43px;
        background: #8d65ff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #f2f2f4;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
      }

      .btnNew {
        margin-left: 13px;
        margin-top: 20px;
        width: 71px;
        height: 43px;
        background: #c6c6c6;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #f2f2f4;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
      }

      .text {
        display: flex;
        font-size: 12px;
        margin-top: 10px;

        .success {
          width: 12px;
          height: 12px;
          margin-left: 20px;
          margin-right: 2px;
        }

        .title {
          width: 219px;
          text-align: left;
        }
      }
    }
  }

  .channel {
    margin-top: 6px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    text-align: center;
    padding: 25px 60px 24px;

    .channel-text {
      width: 235px;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .text {
      display: flex;
      font-size: 12px;
      margin-top: 10px;

      .success {
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }

      .title {
        width: 219px;
        text-align: left;
      }
    }
  }

  .btn {
    margin-left: 13px;
    width: 71px;
    height: 43px;
    background: #8d65ff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
  }

  .tabarbg {
    left: 0;
    position: absolute;
    width: 100%;
    height: 237px;
    bottom: 0;
    background: linear-gradient(180deg, #ffffff 0%, #efe8f9 100%);
    z-index: -10;
  }
}

.popup-bag {
  width: 228px;
  height: 201px;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  box-sizing: border-box;
  padding-top: 8px;
  flex-direction: column;

  img {
    margin: 0 auto;
    width: 91px;
    height: 127px;
  }

  .popup-text {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    line-height: 15px;
    text-align: center;
  }

  .popup-textNEW {
    width: 164px;
    height: 64px;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    line-height: 15px;
  }
}

.popup-bagNew {
  width: 228px;
  height: 201px;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  box-sizing: border-box;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  img {
    margin: 0 auto;
    width: 79px;
    height: 79px;
  }

  .popup-textNEW {
    width: 154px;
    height: 64px;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    line-height: 15px;
    text-align: center;
  }
}
</style>
