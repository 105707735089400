<template>
  <div>
    <Loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        color="#beabff"
    >
    </Loading>
    <div v-if="!loading">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  mounted() {
    if (window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      this.initData(window.Telegram.WebApp.initData)
    } else {
      alert('Please open in telegram')
    }
  },
  components: {
    Loading,
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
  },
  methods: {
    initData(data) {
      this.$store.dispatch('auth', data)
    },
  },
}
</script>

<style></style>
