export const en = {
    bar: {
        wallet: 'Wallet',
        activity: 'Activity',
        profile: "Profile",
    },
    wallet: {
        totalBalance: "Total $Daily Balance",
        send: "Send",
        swap: "Swap",
        deposit: "Deposit",
        more: "More Asset",
        claimReward: "Claim Your Daily Reward",
        DrawReward: "Draw For More $Daily",
        wayTitle: "Ways to get more $Daily",
        inviteTitle: "Invite your friends to join",
        followTitle: "Follow on X account and join chat channel",
    },
    activity: {
        tap: "Tap Here",
        comingsoon: "More coming soon",
        use100: "Use 100 $Daily to draw for up to",
        dice: {
            rule1: "1. Daily Lucky Draw Chance: Earn one raw chance each day after completing your daily check-in. Each draw will cost 100 $Daily.",
            rule2: "2. No Cheating: Use of bots or cheating is strictly prohibited and  leads to disqualification",
        }
    },
    profile: {
        copylink: "Copy link",
        share: "Share",
        earnReward: "Earn rewards",
        recommend: "Recommend",
        invite: "Invite friends",
        link: "Link",
        statistics: "Statistics",
        firstReferral: "First Level Referral",
        secondReferral: "Second Level Referral",
        checkInReward: "Daily checkin reward:",
        referralReward: "Referral reward:",
        dearUser: "Dear @{username}"
    },
    setting: {
        version: "Daily Wallet Version",
        language: "Language",
        support: "Contact Support",
        chooseLanguage: "Choose your Language",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "Community",
        announcement: "Daily Wallet Announcement",
        channel: "Telegram Chat Channel",
        twitter: "Official X Account",
        whitepaper: "Whitepaper",
        support: "Contact Support",
    },
    common: {
        pleaseOpenInTelegram: "Please open in Telegram",
        pleaseOpenPerDay: "You can only roll once per day",
        pleaseOpenBalance: "Your balance is less than 100 $Daily",
        PleaseOpenfirst: "Please claim daily rewards first",
        Congratulations: "Congratulations! You win",
        tomorrow: "$Daily! Try your good luck again tomorrow!",
        hours: "You have claimed daily check-in reward within 24 hours, please try again after",
        successfully: "You have successfully claimed daily check-in reward!",
        Twitter: "Your X account was verified, 800 $Daily was sent to your wallet",
        verify: "verify",
        TGChatChannel: "Join our TG Chat Channel to gain 500 $Daily",
        joinedOurChat: "You have joined our chat channel, 500 $Daily was sent to your wallet",
        VerificationSuccessful: "Verification successful",
        ToComplete: "To complete the verification, please join our chat group first",
        TheVerification: "The verification will take 24 hours to complete",
        ProgramReferral: "Referral Program Rule",
        HowMany: "How many $Daily can I get by referral program?",
        Daily: "$Daily!",
        WhentheUsers: "Whenthe users brought by your invites claim their rewards, you'll get",
        DailyActivity: "Daily Activity Reward: When your invited friends claim their daily rewards, you'll receive",
        DailyFor: "$Daily for each referral!",
        SecondLevel: "Second level reward: When the invited person refers others to register for Daily Wallet ,you'll earn a reward of",
        FirstLevel: "First Level Reward: When someone activates a Daily wallet account by using your referral link, you'll receive a reward of",
        CopySuccessful: "Copy successful",
        RefreshFailed: "Refresh failed",
    },
    errors: {
        tryAgain: "Error! Please try again later.",
        twitterSubmitted: "X account has already submitted.",
        inputTwitter: "Please input correct X handler. with '@'."
    }

}