export const ar = {
    bar: {
        wallet: 'محفظة',
        activity: 'النشاط',
        profile: "الملف الشخصي",
    },
    wallet: {
        totalBalance: "الرصيد الكلي لـ $Daily",
        send: "إرسال",
        swap: "تبادل",
        deposit: "إيداع",
        more: "المزيد من الأصول",
        claimReward: "اطلب مكافأتك اليومية",
        DrawReward: "احصل على المزيد من $Daily",
        wayTitle: "طرق الحصول على المزيد من $Daily",
        inviteTitle: "ادع أصدقائك للانضمام",
        followTitle: "تابع الحساب X وانضم إلى قناة الدردشة",
    },
    activity: {
        tap: "انقر هنا",
        comingsoon: "المزيد قريبًا",
        use100: "استخدم 100$Daily لليانصيب",
        dice: {
            rule1: "1. فرصة السحب اليومي: احصل على فرصة واحدة كل يوم بعد إكمال تسجيل الدخول اليومي. كل سحب سيكلف 100 $Daily.",
            rule2: "2. ممنوع الغش: يحظر استخدام البوتات أو الغش بشكل صارم ويؤدي إلى الإقصاء",
        }
    },
    profile: {
        copylink: "نسخ الرابط",
        share: "مشاركة",
        earnReward: "اكسب مكافآت",
        recommend: "توصية",
        invite: "دعوة الأصدقاء",
        link: "الرابط",
        statistics: "الإحصائيات",
        firstReferral: "الإحالة من المستوى الأول",
        secondReferral: "الإحالة من المستوى الثاني",
        checkInReward: "مكافأة تسجيل الدخول اليومية:",
        referralReward: "مكافأة الإحالة:",
        dearUser: "عزيزي @{username}",
    },
    setting: {
        version: "إصدار Daily Wallet",
        language: "اللغة",
        support: "الاتصال بالدعم",
        chooseLanguage: "اختر لغتك",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Runssian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "المجتمع",
        announcement: "إعلان Daily Wallet",
        channel: "قناة الدردشة في تيليغرام",
        twitter: "الحساب الرسمي X",
        whitepaper: "Whitepaper",
        support: "احصل على مساعدة",
    },
    common: {
        pleaseOpenInTelegram: "يرجى فتحه في تليجرام",
        pleaseOpenPerDay: "يمكنك اللعب مرة واحدة فقط في اليوم",
        pleaseOpenBalance: "الرصيد غير كاف",
        PleaseOpenfirst: "يرجى المطالبة بمكافآت تسجيل الدخول اليومية أولاً",
        Congratulations: "تهانينا! لقد فزت",
        tomorrow: "$Daily! جرب حظك الجيد مرة أخرى غداً!",
        hours: "لقد قمت بالمطالبة بمكافأة تسجيل الدخول اليومية خلال 24 ساعة، يرجى المحاولة مرة أخرى بعد ذلك",
        successfully: "لقد قمت بالمطالبة بنجاح بمكافأة تسجيل الدخول اليومية!",
        Twitter: "تم التحقق من حسابك على تويتر، وتم إرسال 800 $Daily إلى محفظتك",
        verify: "تحقق",
        TGChatChannel: "انضم إلى قناة الدردشة TG لدينا لكسب 500 $Daily",
        joinedOurChat: "لقد انضممت إلى قناة الدردشة لدينا، وتم إرسال 500 $Daily إلى محفظتك",
        VerificationSuccessful: "تم التحقق بنجاح",
        ToComplete: "لإكمال عملية التحقق، يرجى الانضمام إلى مجموعة الدردشة لدينا أولاً",
        TheVerification: "سوف تستغرق عملية التحقق 24 ساعة لإكمالها",
        ProgramReferral: "قواعد برنامج الإحالة",
        HowMany: "كم عدد $Daily التي يمكنني الحصول عليها من خلال برنامج الإحالة؟",
        Daily: "$Daily!",
        WhentheUsers: "عندما يطالب المستخدمون الذين جلبتهم بدعواتك بمكافآتهم، ستحصل",
        DailyActivity: "مكافأة النشاط اليومي: عندما يطالب أصدقاؤك المدعوون بمكافآتهم اليومية، ستتلقى",
        DailyFor: "$Daily لكل إحالة!",
        SecondLevel: "مكافأة المستوى الثاني: عندما يحيل الشخص المدعو آخرين للتسجيل في Daily Wallet، ستكسب مكافأة",
        FirstLevel: "مكافأة المستوى الأول: عندما يفعل شخص ما حساب Daily Wallet باستخدام رابط الإحالة الخاص بك، ستتلقى مكافأة",
        CopySuccessful: "تم النسخ بنجاح",
        RefreshFailed: "فشل التحديث",
    },
    errors: {
        tryAgain: "خطأ! الرجاء معاودة المحاولة في وقت لاحق",
        twitterSubmitted: "تم حفظ اسم حساب X الخاص بك",
        inputTwitter: "الرجاء إدخال اسم حساب X الصحيح، مع وجود @ في المقدمة."
    }
}
