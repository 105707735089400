export const india = {
    Bar: {
        Wallet: "बटुआ",
        Activity: "गतिविधि",
        Profile: "प्रोफ़ाइल",
    },
    Wallet: {
        TotalBalance: "$Daily कुल शेष",
        Send: "भेजें",
        Swap: "मुद्रा विनिमय",
        Deposit: "जमा करें",
        More: "अधिक संपत्ति",
        ClaimReward: "अपना दैनिक इनाम प्राप्त करें",
        DrawReward: "$Daily लकी ड्रा",
        WayTitle: "$Daily पाने के तरीके",
        InviteTitle: "अपने दोस्तों को आमंत्रित करें",
        FollowTitle: "X खाते को फॉलो करें और चैट चैनल में शामिल हों",
    },
    Activity: {
        Tap: "लकी ड्रॉ शुरू करें",
        Comingsoon: "और अधिक गतिविधियों की प्रतीक्षा करें",
        Use100: "लकी ड्रा के लिए 100 $Daily का उपयोग करें",
        dice: {
            Rule1: "1. दैनिक लकी ड्रॉ का अवसर: प्रतिदिन साइन इन करने के बाद एक मूल अवसर प्राप्त करें। प्रत्येक ड्रा 100 $Daily खर्च होगा",
            Rule2: "2. धोखाधड़ी पर प्रतिबंध: रोबोट का उपयोग या धोखाधड़ी सख्त मना है, उल्लंघन करने वालों को अयोग्य घोषित किया जाएगा",
        }
    },
    Profile: {
        Copylink: "लिंक कॉपी करें",
        Share: "साझा करें",
        EarnReward: "इनाम कमाएँ",
        Recommend: "सिफारिश करें",
        Invite: "मित्रों को आमंत्रित करें",
        Link: "लिंक",
        Statistics: "निमंत्रण डेटा",
        FirstReferral: "प्रथम स्तरीय निमंत्रण",
        SecondReferral: "द्वितीय स्तरीय निमंत्रण",
        CheckInReward: "दैनिक साइन इन इनाम:",
        ReferralReward: "सिफारिशी इनाम:",
        DearUser: "प्रिय @{username}",
    },
    Setting: {
        Version: "Daily Wallet संस्करण",
        Language: "भाषा",
        Support: "सहायता प्राप्त करें",
        ChooseLanguage: "अपनी भाषा चुनें",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Runssian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    Community: {
        Title: "समुदाय",
        Announcement: "Daily Wallet समाचार घोषणा",
        Channel: "Telegram चैट चैनल",
        Twitter: "आधिकारिक X खाता",
        Whitepaper: "Whitepaperर",
        Support: "सहायता प्राप्त करें",
    },
    Common: {
        PleaseOpenInTelegram: "कृपया Telegram में खोलें",
        PleaseOpenPerDay: "आप प्रतिदिन केवल एक बार लॉटरी में भाग ले सकते हैं",
        PleaseOpenBalance: "आपके बैलेंस में 100 $Daily से कम है",
        PleaseOpenfirst: "कृपया पहले दैनिक साइन-इन इनाम प्राप्त करें",
        Congratulations: "बधाई हो! आप जीत गए हैं",
        Tomorrow: "$Daily! कल फिर अपनी किस्मत आजमाएं!",
        Hours: "आपने पिछले 24 घंटों में पहले ही दैनिक साइन-इन इनाम प्राप्त कर लिया है, कृपया बाद में पुनः प्रयास करें",
        Successfully: "आपने सफलतापूर्वक दैनिक साइन-इन इनाम प्राप्त कर लिया है!",
        Twitter: "आपका X खाता सत्यापित हो गया है, 800 $Daily आपके वॉलेट में भेज दिए गए हैं",
        Verify: "सत्यापित करें",
        TGChatChannel: "हमारे TG चैट चैनल में शामिल होकर 500 $Daily प्राप्त करें",
        JoinedOurChat: "आप हमारे चैट चैनल में शामिल हो गए हैं, 500 $Daily आपके वॉलेट में भेज दिए गए हैं",
        VerificationSuccessful: "सत्यापन सफल",
        ToComplete: "सत्यापन पूरा करने के लिए, कृपया पहले हमारे चैट समूह में शामिल हों",
        TheVerification: "यह सत्यापन 24 घंटों के भीतर पूरा हो जाएगा",
        ProgramReferral: "रेफरल प्रोग्राम नियम",
        HowMany: "मैं रेफरल प्रोग्राम के माध्यम से कितना $Daily प्राप्त कर सकता हूँ?",
        Daily: "$Daily!",
        WhentheUsers: "जब आपके आमंत्रित लोग इनाम प्राप्त करते हैं, तो आपको मिलेगा",
        DailyActivity: "दैनिक गतिविधि इनाम: जब आपके द्वारा आमंत्रित मित्र उनका दैनिक इनाम प्राप्त करते हैं, तो आपको मिलेगा",
        DailyFor: "प्रत्येक सिफारिश के लिए $Daily!",
        SecondLevel: "दूसरे स्तर का इनाम: जब आपके द्वारा आमंत्रित व्यक्ति अन्य लोगों को Daily Wallet के लिए रजिस्टर कराता है, तो आपको इनाम मिलेगा",
        FirstLevel: "पहले स्तर का इनाम: जब कोई आपके रेफरल लिंक का उपयोग करके Daily Wallet खाता सक्रिय करता है, तो आपको इनाम मिलेगा",
        CopySuccessful: "कॉपी सफल",
        RefreshFailed: "ताज़ा करने में विफल",
        Errors: {
            TryAgain: "त्रुटि! कृपया बाद में पुनः प्रयास करें",
            TwitterSubmitted: "आपके X खाते का नाम पहले ही सहेज लिया गया है",
            InputTwitter: "कृपया सही X खाते का नाम दर्ज करें, इससे पहले '@' होना चाहिए"
        }

    }
}