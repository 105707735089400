export const kr = {
    bar: {
        wallet: '지갑',
        activity: '활동',
        profile: "프로필",
    },
    wallet: {
        totalBalance: "$Daily 총 잔액",
        send: "보내기",
        swap: "토큰 스왑",
        deposit: "입금",
        more: "기타 자산",
        claimReward: "일일 보상을 받으세요",
        DrawReward: "$Daily 더 그리기",
        wayTitle: "$Daily 를 더 많이 얻는 방법",
        inviteTitle: "친구들을 초대하세요",
        followTitle: "X 계정을 팔로우하고 채팅 채널에 참여하세요",
    },
    activity: {
        tap: "여기를 탭하세요",
        comingsoon: "곧 더 많은 것이 올 예정",
        use100: "매일 100달러로 추첨하세요",
        dice: {
            rule1: "1. 일일 행운의 추첨 기회: 매일 체크인을 완료한 후 원시 기회를 얻습니다. 각 추첨은 100 $Daily 가 소요됩니다.",
            rule2: "2. 부정 행위 금지: 봇이나 부정 행위의 사용은 엄격히 금지되며 자격 박탈로 이어집니다",
        }
    },
    profile: {
        copylink: "링크 복사",
        share: "공유하기",
        earnReward: "보상 받기",
        recommend: "추천",
        invite: "친구 초대",
        link: "링크",
        statistics: "통계",
        firstReferral: "첫 번째 레벨 추천",
        secondReferral: "두 번째 레벨 추천",
        checkInReward: "일일 체크인 보상:",
        referralReward: "추천 보상:",
        dearUser: "@{username} 님",
    },
    setting: {
        version: "Daily Wallet 버전",
        language: "언어",
        support: "지원 연락처",
        chooseLanguage: "언어 선택",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Russian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "커뮤니티",
        announcement: "Daily Wallet 공지",
        channel: "Telegram 채팅 채널",
        twitter: "공식 X 계정",
        whitepaper: "Whitepaper",
        support: "도움을 받다",
    },
    common: {
        pleaseOpenInTelegram: "텔레그램에서 열어주세요",
        pleaseOpenPerDay: "하루에 한 번만 돌릴 수 있습니다",
        pleaseOpenBalance: "잔액이 부족합니다",
        PleaseOpenfirst: "먼저 일일 로그인 보상을 요청하세요",
        Congratulations: "축하합니다! 당신이 이겼습니다",
        tomorrow: "$Daily! 내일 다시 행운을 시험해보세요!",
        hours: "24시간 내에 일일 체크인 보상을 받았습니다, 다시 시도해주세요",
        successfully: "일일 체크인 보상을 성공적으로 받았습니다!",
        Twitter: "트위터 계정이 인증되었으며, 800 $Daily가 지갑으로 전송되었습니다",
        verify: "인증",
        TGChatChannel: "저희 TG 채팅 채널에 가입하고 500 $Daily를 받으세요",
        joinedOurChat: "채팅 채널에 가입하셨고, 500 $Daily가 지갑으로 전송되었습니다",
        VerificationSuccessful: "인증 성공",
        ToComplete: "인증을 완료하려면 먼저 저희 채팅 그룹에 가입하세요",
        TheVerification: "인증 완료까지 24시간이 소요됩니다",
        ProgramReferral: "추천 프로그램 규칙",
        HowMany: "추천 프로그램으로 얼마나 많은 $Daily를 받을 수 있나요?",
        Daily: "$Daily!",
        WhentheUsers: "귀하의 초대를 받은 사용자가 보상을 청구하면, 귀하는 받게 됩니다",
        DailyActivity: "일일 활동 보상: 초대한 친구가 일일 보상을 청구할 때마다, 귀하는 받게 됩니다",
        DailyFor: "각 추천에 대해 $Daily!",
        SecondLevel: "2단계 보상: 초대받은 사람이 다른 사람을 Daily Wallet에 등록하면, 보상을 받게 됩니다",
        FirstLevel: "1단계 보상: 누군가가 귀하의 추천 링크를 사용하여 Daily Wallet 계정을 활성화하면, 보상을 받게 됩니다",
        CopySuccessful: "복사 성공",
        RefreshFailed: "새로고침 실패",
    },
    errors: {
        tryAgain: "실수! 나중에 다시 시도 해주십시오",
        twitterSubmitted: "귀하의 X 계정 이름이 저장되었습니다",
        inputTwitter: "앞에 '@'을 포함하여 올바른 X 계정 이름을 입력하세요."
    }

}
