export const zh = {
    bar: {
        wallet: '钱包',
        activity: '活动',
        profile: "个人资料",
    },
    wallet: {
        totalBalance: "$Daily 总余额",
        send: "发送",
        swap: "币币兑换",
        deposit: "充币",
        more: "更多资产",
        claimReward: "领取您的每日奖励",
        DrawReward: "$Daily 幸运大转盘",
        wayTitle: "获取更多 $Daily 的方式",
        inviteTitle: "邀请您的朋友加入",
        followTitle: "关注 X 账号并加入聊天频道",
    },
    activity: {
        tap: "开始抽奖",
        comingsoon: "敬请期待更多活动",
        use100: "使用 100 $Daily 进行抽奖",
        dice: {
            rule1: "1. 每日幸运抽奖机会：在完成每日签到后获得一次原始机会。每次抽奖将消耗 100 $Daily",
            rule2: "2. 禁止作弊：严禁使用机器人或作弊，违者将被取消资格",
        }
    },
    profile: {
        copylink: "复制链接",
        share: "分享",
        earnReward: "赚取奖励",
        recommend: "推荐",
        invite: "邀请朋友",
        link: "链接",
        statistics: "邀请数据",
        firstReferral: "一级邀请",
        secondReferral: "二级邀请",
        checkInReward: "每日签到奖励：",
        referralReward: "推荐奖励：",
        dearUser: "亲爱的 @{username}",
    },
    setting: {
        version: "Daily Wallet 版本",
        language: "语言",
        support: "获取帮助",
        chooseLanguage: "选择你的语言",
        english: "English",
        chinese: "中文",
        japanese: "日本語",
        korean: "한국어",
        espanol: "Español",
        portuguese: "Português",
        turkish: "Türkçe",
        indonesia: "Bahasa Indonesia",
        French: "Français",
        Runssian: "Русский",
        Bengali: "বাংলা",
        Hindi: "हिंदी",
    },
    community: {
        title: "社区",
        announcement: "Daily Wallet 消息公告",
        channel: "Telegram 聊天频道",
        twitter: "官方 X 账号",
        whitepaper: "白皮书",
        support: "获得帮助",
    },
    common: {
        pleaseOpenInTelegram: "请在 Telegram 中打开",
        pleaseOpenPerDay: "您每天只能抽奖一次",
        pleaseOpenBalance: "您的余额不足 100 $Daily",
        PleaseOpenfirst: "请先领取每日签到奖励",
        Congratulations: "恭喜您！您赢得了",
        tomorrow: "$Daily！明天再试试您的好运吧！",
        hours: "您在 24 小时内已领取每日签到奖励，请过后再试",
        successfully: "您已成功领取每日签到奖励！",
        Twitter: "您的 X 账户已验证，800 $Daily 已发送至您的钱包",
        verify: "验证",
        TGChatChannel: "加入我们的 TG 聊天频道可获得 500 $Daily",
        joinedOurChat: "您已加入我们的聊天频道，500 $Daily 已发送至您的钱包",
        VerificationSuccessful: "验证成功",
        ToComplete: "要完成验证，请先加入我们的聊天群",
        TheVerification: "此验证将在24小时内完成",
        ProgramReferral: "推荐计划规则",
        HowMany: "我通过推荐计划可以获得多少 $Daily？",
        Daily: "$Daily！",
        WhentheUsers: "当您的邀请者领取奖励时，您将获得",
        DailyActivity: "每日活动奖励：当您邀请的朋友领取他们的每日奖励时，您将获得",
        DailyFor: "每个推荐 $Daily！",
        SecondLevel: "第二级奖励：当被邀请人推荐其他人注册 Daily Wallet 时，您将获得奖励",
        FirstLevel: "一级奖励：当有人使用您的推荐链接激活 Daily Wallet 账户时，您将收到奖励",
        CopySuccessful: "复制成功",
        RefreshFailed: "刷新失败",
    },
    errors: {
        tryAgain: "错误！请在稍后重试",
        twitterSubmitted: "您的 X 账户名已经被保存",
        inputTwitter: "请输入正确的 X 账户名，前面需要带上 '@'"
    }

}