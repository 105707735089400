<template>
  <div>
    <tabBar :current="'2'"></tabBar>

    <div class="container">
      <div class="top">
        <div class="heander">
          <img src="../../assets/image/text.png" mode="scaleToFill" />
        </div>
        <div class="text_title">{{ $t("profile.dearUser", { username: userName }) }}</div>
        <div class="text_new">
          <div style="color: #8c64fe; margin: 0 10px">{{ balance.balance }}</div>
          $Daily
        </div>
        <div class="nav">
          <div class="nav_">
            <img src="../../assets/image/nav1.png" mode="" />
            <div class="">{{ $t('profile.copylink') }}</div>
          </div>
          <div class="">·&gt;</div>
          <div class="nav_">
            <img src="../../assets/image/nav2.png" mode="" />
            <div class="">{{ $t('profile.share') }}</div>
          </div>
          <div class="">·&gt;</div>
          <div class="nav_">
            <img src="../../assets/image/nav3.png" mode="" />
            <div class="">{{ $t('profile.earnReward') }}</div>
          </div>
        </div>
        <div class="title_list">
          <div class="list_left">{{ $t('profile.recommend') }}</div>
          <div class="list_right">{{ $t('profile.invite') }}</div>
        </div>

        <div class="index_input">
          <van-field v-model="value" center placeholder="Link" class="text__value">
            <template #button>
              <span slot="suffix" margin="0 5px 0 0" type="tips" style="font-weight: 600">
                {{ referralLink }}
              </span>
            </template>
          </van-field>

          <div class="btn" @click="copyToClipboard">
            {{ $t('profile.copylink') }}
          </div>
          <div class="textHow" @click="toDetails">
            {{ $t('common.HowMany') }}
          </div>
        </div>
      </div>
      <div class="botm">
        <div class="box">
          <div class="box-title">{{ $t('profile.statistics') }}</div>
          <div class="botm-box">
            <div class="main-box">
              <div class="box-title">{{ $t('profile.firstReferral') }}</div>
              <div class="box-text">{{ balance.fisrstSubUserCount }}</div>
              <img src="../../assets/image/lines.png" mode="" />
            </div>
            <div class="main-box">
              <div class="box-title">{{ $t('profile.secondReferral') }}</div>
              <div class="box-text">{{ balance.secondSubUserCount }}</div>
              <img src="../../assets/image/lines.png" mode="" />
            </div>
          </div>

          <div class="botm-text">
            <img src="../../assets/image/gift.png" mode="" />
            {{ $t('profile.checkInReward') }}
            <div class="botm-manin">{{ balance.checkinBalance }}</div>
            $Daily!
          </div>
          <div class="botm-text">
            <img src="../../assets/image/home.png" mode="" />
            {{ $t('profile.referralReward') }}
            <div class="botm-manin">{{ balance.inviteBalance }}</div>
            $Daily
          </div>
        </div>
      </div>
    </div>

    <van-overlay :show="showSuccess" @click="showSuccess = false">
      <div class="wrapper">
        <div class="disBox">
          <img src="../../assets/image/success.png" mode="scaleToFill" />
          <div class="text">{{ $t('common.CopySuccessful') }}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import tabBar from '@/components/tabbar.vue'

export default {
  components: { tabBar },
  data() {
    return {
      value: '',
      showSuccess: false,
    }
  },
  computed: {
    balance() {
      return this.$store.state.balance
    },
    referralLink() {
      return `https://t.me/DailyWalletbot?start=${this.$store.state.user.userID}`
    },
    userName() {
      return this.$store.state.user.username || `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`
    }
  },
  methods: {
    copyToClipboard() {
      this.$copyText(this.referralLink)
      this.showSuccess = true
    },
    toDetails() {
      this.$router.push({ name: "ProfileIndex" })
    },
  },
}
</script>

<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.disBox {
  width: 129px;
  height: 32px;
  background: rgba(0, 0, 0, 0.73);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 14px;
    height: 14px;
  }

  .text {
    font-size: 12px;
    color: #ffffff;
  }
}

.text__value {
  width: 100%;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  border: none;
  background: #f8fafc;
}

.index_input {
  width: 345px;
  height: 145px;
  background: #f8fafc;
  border-radius: 9px;
  opacity: 1;
  border: 1px solid #f2f2f4;
  margin: 5px auto 0;
  padding: 15px;
  box-sizing: border-box;

  .btn {
    width: 100%;
    height: 44px;
    background: #000000;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    line-height: 44px;
    color: #fff;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
  }
}

.top {
  width: 100%;
  height: 328px;
  background-image: url('../../assets/image/bag.png');
  background-size: 100% 100%;
  padding-top: 30px;
  box-sizing: border-box;

  .heander {
    padding-left: 13px;
  }

  .text_title {
    width: 335px;
    margin: 0 auto;
    margin-top: 21px;
    font-size: 15px;
    font-family: TencentSans, TencentSans;
    font-weight: 600;
    color: #060606;
    font-style: italic;
  }

  .text_new {
    width: 332px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 22px;
    font-family: TencentSans, TencentSans;
    font-weight: 600;
    color: #060606;
    display: flex;
    font-style: italic;
  }

  .nav {
    width: 345px;
    height: 85px;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    margin: 0 auto;
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;

    color: #7e3df8;

    .nav_ {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 11px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #000000;
      line-height: 13px;

      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      img {
        width: 29px;
        height: 29px;
      }
    }
  }

  .title_list {
    width: 345px;
    margin: 0 auto;
    margin-top: 12.5px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 400;
    color: #000000;

    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    .list_right {
      font-size: 13px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #6e6e73;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  .hard {
    width: 345px;
    height: 127px;
    margin: 0 auto;
    margin-top: 14px;
    background: #f8fafc;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #f2f2f4;

    .hard_hader {
      padding: 22.5px 20px;
      display: flex;
      justify-content: space-between;

      .hader-left {
        font-size: 14px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        color: #6e6e73;
      }

      .hader-right {
        font-size: 14px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 600;
        color: #000000;
        line-height: 16.5px;
        letter-spacing: 1px;
        // -webkit-text-stroke: 1px #000000;
        text-stroke: 1px #000000;
      }
    }

    .hard-btn {
      width: 305px;
      height: 44px;
      background: #000000;
      border-radius: 24px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 16px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      color: #ffffff;
    }
  }
}

.botm {
  margin-top: 120px;
  min-height: 365px;
  background: linear-gradient(90deg, #f6f5f8 0%, #efe8f9 100%);
  opacity: 1;
  display: flex;
  justify-content: center;

  .box {
    margin-top: 10px;
    width: 345px;
    height: 223px;
    background: #f8fafc;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #f2f2f4;
    padding: 18px 15px;
    box-sizing: border-box;

    .box-title {
      font-size: 16px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 500;
      color: #000000;
    }

    .botm-box {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .main-box {
        width: 152px;
        height: 82px;
        background: #ffffff;
        border-radius: 9px;
        opacity: 1;
        border: 1px solid #f2f2f4;
        padding: 16px 9px;
        box-sizing: border-box;
        position: relative;

        .box-title {
          font-size: 10px;
          font-family: PingFang HK, PingFang HK;
          font-weight: 400;
          color: #828589;
        }

        .box-text {
          margin-top: 10px;
          font-size: 18px;
          font-family: PingFang HK, PingFang HK;
          font-weight: 600;
          color: #000000;
        }

        img {
          position: absolute;
          width: 66px;
          height: 18px;
          bottom: 20px;
          right: 6px;
        }
      }
    }

    .botm-text {
      display: flex;
      align-items: center;
      margin-top: 22.5px;

      font-size: 12px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #000000;

      img {
        width: 16px;
        height: 13.5px;
        margin-right: 13px;
      }

      .botm-manin {
        color: #ff0002;
        margin: 0 2.5px;
      }
    }
  }
}

.textHow {
  font-size: 12px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 400;
  color: #7c19ee;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
